import { mapState, mapActions } from 'vuex';
import { validateForm, mask } from "@/assets/js/Utils.js"

const fieldsToCompare = [
    "rua",
    "numero",
    "complemento",
    "bairro",
    "cidade",
    "estado",
    "pais",
    "cep",
    "ponto_referencia",
    "telefone_contato"
];

export default {
    props: {
        addressToUpdate: Object
    },

    data: () => ({
        address: {
            id_endereco_entrega: "",
            identificacao: "",
            rua: "",
            numero: 0,
            complemento: "",
            bairro: "",
            cidade: "",
            estado: "",
            pais: "BRA",
            cep: "",
            ponto_referencia: "",
            telefone_contato: "",
        },
        isDefaultDeliveryAddress: false,
        savedAddress: null,
        useAsDefaultDeliveryAddress: false,
        createMode: true,
    }),

    async created() {
        if(this.addressToUpdate == null)
            return this.createMode = true;

        this.createMode = false;
        this.address = this.addressToUpdate;
        this.savedAddress = { ...this.addressToUpdate };

        if(this.address.id_endereco_entrega == this.user.endereco_entrega_padrao) {
            this.isDefaultDeliveryAddress = true;
            this.useAsDefaultDeliveryAddress = true;
        }
    },

    mounted() {
        if(this.createMode && this.user.deliveryAddresses.length == 0)
            this.useAsDefaultDeliveryAddress = true;
    },

    computed: {
        ...mapState(["user"])
    },

    methods: {
        async showList(forceFetch = false) {
            if(forceFetch)
                await this.actionSetDeliveryAddresses(forceFetch);
            this.$emit("show-component", "list");
        },

        maskField(maskKey) {
            return mask[maskKey] || mask.none
        },

        rulesField(mask) {
            if(!mask) return;
            return validateForm[mask || 'required']
        },

        cancel() {
            this.showList();
        },

        addressChanged() {
            for (const field in this.address) {
                if(!fieldsToCompare.includes(field)) continue;

                let equal;
                if(["cep", "telefone_contato"].includes(field))
                    equal = this.compareOnlyNumbers(this.address[field], this.savedAddress[field]);
                else
                    equal = this.address[field] == this.savedAddress[field];

                if(!equal) return true;
            }
            return false;
        },

        addressIdentificationChanged() {
            return this.address["identificacao"] != this.savedAddress["identificacao"];
        },

        compareOnlyNumbers(value1, value2) {
            return this.getOnlyNumbers(value1) == this.getOnlyNumbers(value2);
        },

        getOnlyNumbers(text) {
            return text.replace(/[^0-9]/g, "");
        },

        changedToDefaultDeliveryAddress() {
            return !this.isDefaultDeliveryAddress && this.useAsDefaultDeliveryAddress;
        },

        async onSaveAddress() {
            let success = true;

            if(this.createMode || this.addressChanged())
                success = await this.onCreateAddress();

            else if (this.addressIdentificationChanged())
                success = await this.onUpdateAddress();

            else if(this.changedToDefaultDeliveryAddress())
                success = await this.onSetDefaultDeliveryAddress(this.address.id_endereco_entrega);

            if (!success)
                return this.$toasted.global.error({ msg: "Erro ao salvar endereço!" });

            this.$toasted.global.success({ msg: "Endereço salvo com sucesso!" });
            return this.showList(true);
        },

        async onCreateAddress() {
            const res = await this.handleCreateAddress();
            if(!res.created) return false;
            
            if(!this.createMode) await this.handleRemoveAddress();
            
            if(this.useAsDefaultDeliveryAddress)
               await this.onSetDefaultDeliveryAddress(res.addressId);

            return true;
        },

        async onUpdateAddress() {
            const updated = await this.handleUpdateAddress();
            if(!updated) return false;

            if(this.changedToDefaultDeliveryAddress())
                await this.onSetDefaultDeliveryAddress(this.address.id_endereco_entrega);

            return true;
        },

        async onSetDefaultDeliveryAddress(defaultDeliveryAddress) {
            const setted = await this.handleSetDefaultDeliveryAddress(defaultDeliveryAddress);
            if(setted)
                this.actionSetDefaultDeliveryAddress(defaultDeliveryAddress);
            return setted;
        },

        async onRemoveAddress() {
            const removed = await this.handleRemoveAddress();
            if(!removed)
                return this.$toasted.global.error({ msg: "Erro ao remover endereço!" })

            if(this.address == this.user.selectedDeliveryAddress) {
                this.actionSetSelectedDeliveryAddress(null);
                this.$emit("delivery-address-updated");
            }

            this.$toasted.global.success({ msg: "Endereço removido com sucesso!" })
            return this.showList(true);
        },

        async handleCreateAddress() {
            try {
                const addressToCreate = { ...this.address, numero: +this.address.numero }
                const res = await window.MODULOS.deliveryAddresses.post(this.user.id_cliente, addressToCreate);

                const success = res.status == 201;
                let addressId = null;
                if(success) addressId = res.id_endereco_entrega;
                return { created: success, addressId};

            } catch (error) {
                return { created: false, addressId: null};
            }
        },

        async handleUpdateAddress() {
            try {
                const payload = { identificacao :this.address.identificacao }
                const res = await window.MODULOS.deliveryAddresses.put(this.address.id_endereco_entrega, this.user.id_cliente, payload);
                return res.status == 200;
            } catch (error) {
                return false;
            }
        },

        async handleRemoveAddress() {
            try {
                const res = await window.MODULOS.deliveryAddresses.delete(this.address.id_endereco_entrega, this.user.id_cliente);
                return res.status == 200;
            } catch (error) {
                return false;
            }    
        },

        async handleSetDefaultDeliveryAddress(addressId) {
            try {
                const payload = { endereco_entrega_id: addressId };
                const res = await window.MODULOS.client.defaultDeliveryAddress(this.user.id_cliente, payload);
                return res.status == 200;
            } catch (error) {
                return false;
            }    
        },

        ...mapActions(["actionSetDeliveryAddresses", "actionSetDefaultDeliveryAddress", "actionSetSelectedDeliveryAddress"])
    },
}