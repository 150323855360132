import Vue from 'vue'
import App from './template/app/App.vue'
import './config/GlobalVariables'
import './config/GlobalComponents'
import './config/VueToasted';
import './config/V-mask';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

if (location.protocol != 'https:' && (!location.host.includes('localhost') && !location.host.includes('192.168'))){
  location.protocol = 'https:'
}
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
