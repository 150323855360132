import { mapState } from 'vuex'
import RedemptionFirstStep from "./redemption_first_step/RedemptionFirstStep.vue"
import RedemptionSecondStep from "./redemption_second_step/RedemptionSecondStep.vue"

export default {
  props: { value: Object },

  components: { RedemptionFirstStep, RedemptionSecondStep },

  data: () => ({
    currentStep: 2,
    requestDeliveryAddress: false
  }),

  created() {
    this.requestDeliveryAddress = this.checkRequestDeliveryAddress();
    if(this.requestDeliveryAddress)
      this.currentStep = 1;
  },

  methods: {
    incrementRedemptionStep() {
      this.currentStep++;
    },

    decrementRedemptionStep() {
      this.currentStep--;
    },

    checkRequestDeliveryAddress() {
      const contractedResource = this.establishment?.contractedResources.includes('endereco_entrega__');
      const configEnabled = this.establishment?.configuracoes.habilitar_endereco_entrega;
      return contractedResource && configEnabled && this.value.solicitar_endereco_entrega;
    },
  },

  computed: {
    ...mapState(["establishment"]),
  },
};