import Banner from '../banner/Banner.vue'
import MenuLogged from './menu_logged/MenuLogged.vue'

import { mapState } from 'vuex'

export default {
    name: 'Navbar',

    props: {
        customItems: Object
    },

    components: { Banner, MenuLogged },

    data: function(){
        return {
            itemsNavbar: {},
            showBarMobile: false,
            loading: true,
            isLogged : false
        }
    },

    async created() {
        window.scrollTo(0, 0)
        if (!this.customItems)
            this.itemsNavbar = this.DATACUSTOM.text.navbar
        else
            this.itemsNavbar = this.customItems.navbar

        this.$root.$on('closeModal',(payload) =>{
            this.showBarMobile = payload
        })
        this.isLogged = sessionStorage.getItem('user') != null;
        setInterval(() => {
            this.isLogged = sessionStorage.getItem('user') != null;
        }, 1000);

        this.loading = false
    },

    mounted() {
        window.addEventListener('click',(event) => {
            {
                if(event.path != null)
                if (event.path[2].id == 'iconMenu') {
                    return;
                }
                this.showBarMobile = false;
            }
        });
    },

    computed: {
        ...mapState(["establishment", "user"])
    }
}