export default {
  props: {
    btnName: String,
    title: String,
    subtitle: String,
    disabled: Boolean,
    callback: Function,
  },

  data: () => ({
    dialog: false,
  })
};