export default {
  props: {
    value: Number,
    max: Number,
  },
  
  methods: {
    changeAmount(increment) {
      if (this.value == 1 && increment < 0) return;
      if (this.max != undefined && increment > 0 && this.value >= this.max) {
        this.$toasted.global.error({ msg: "Limite de estoque atingido!" })
        return
      }
      this.$emit('input', this.value + increment)
    },
  },
};