import ColorPicker from './color_picker/ColorPicker.vue'

export default {
    components: { ColorPicker },

    data: () => ({
        themeName: 'Personalizacao',
        colors: [
            {
                nameField: 'primary',
                value: '',
                label: 'Defina a cor primária',
                tooltip: 'Cor principal do site, usada em botões e ícones'
            },
            {
                nameField: 'primaryText',
                value: '',
                label: 'Defina a cor primária de texto',
                tooltip: 'Cor principal dos textos do site'
            },
            {
                nameField: 'secondary',
                value: '',
                label: 'Defina a cor secundária',
                tooltip: 'Cor usada no background do bannerApp'
            },
            {
                nameField: 'secondaryText',
                value: '',
                label: 'Defina a cor secundária de texto',
                tooltip: 'Cor usada em subtítulos'
            },
            {
                nameField: 'colorTextMenu',
                value: '',
                label: 'Defina a cor do texto do menu',
                tooltip: 'Cor usada no texto do menu'
            },
            {
                nameField: 'backgroundMenu',
                value: '',
                label: 'Defina a cor do background do menu e das box',
                tooltip: 'Cor usada de fundo no menu'
            },
            {
                nameField: 'background',
                value: '',
                label: 'Defina a cor do background do site',
                tooltip: 'Cor usada de fundo em todo site'
            },
            {
                nameField: 'backgroundFooterLight',
                value: '',
                label: 'Defina a cor do background claro do rodapé',
                tooltip: 'Cor "clara" usada de fundo no rodapé'
            },
            {
                nameField: 'backgroundFooterDark',
                value: '',
                label: 'Defina a cor do background escuro do rodapé',
                tooltip: 'Cor "escura" usada de fundo no rodapé'
            }
        ],
        colorSelected: null
    }),

    async created(){
        const resp = await this.MODULOS.configStore.getConfigStore()
        if(resp.data.themeName == 'Personalizacao'){
            this.colors.forEach(element => {
                element.value = resp.data.colors[element.nameField]
            })
            return
        }
        if (sessionStorage.getItem('customColors')) {
            const customColors = JSON.parse(sessionStorage.getItem('customColors'))
            this.colors.forEach(element => {
                element.value = customColors[element.nameField]
            })
        }
    },
    
    methods: {
        saveConfig() {
            if(!this.verifyColors())
                return

            var body = {}
            this.colors.forEach(element => {
                // Slice para remover a transparência que fica no final do hexa
                body[element['nameField']] = element.value.slice(0,7)
            })

            this.$root.$emit('customTheme', {
                colors: body,
                themeName: this.themeName
            })

        },
        verifyColors() {
            var allColors = this.colors.filter(function (element) {
                if (element.value) {
                    return element
                }
            })

            if (allColors.length < 9) {
                this.$toasted.global.error({ msg: 'Favor preencher todas as cores!' })
                return false
            }
            this.$toasted.global.success({ msg: 'Personalização de cores definida com sucesso!' })
            return true
        },
        loadColorPicker(color) {
            this.colorSelected = color
        }
    }
}