import MyPointsDefaultModel from "./my_points_default_model/MyPointsDefaultModel.vue";
import MyPointsMetadataModel from "./my_points_metadata_model/MyPointsMetadataModel.vue";
import { mapState } from "vuex";

export default {
  components: { MyPointsDefaultModel, MyPointsMetadataModel },

  props: {
    customItems: Object,
  },

  methods: {
    useMetadataModel() {
      return this.establishment.contractedResources?.includes("pontos_metadados__");
    }
  },

  computed: {
    ...mapState(["establishment"])
  }
};
