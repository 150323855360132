import Register from '../../register/Register.vue'

export default {
    components: { 
        Register
    },

    data: () => ({
        componentSelected: null,
        customItemsRegister: {}
    }),

    created(){
        this.customItemsRegister = this.DATACUSTOM.text
    },

    methods: {
        selectComponent() {
            this.componentSelected = Register
            this.$root.$emit('selectComponent', {
                componentSelected: this.componentSelected,
                customItems: this.customItemsRegister.register
            })
        }
    }
}