import HowItWorks from "../../components/how_it_works/HowItWorks.vue"
import Prizes from "../../components/prizes/Prizes.vue"
import BannerApp from "../../components/banner_app/BannerApp.vue"

export default {
    components: { HowItWorks, Prizes, BannerApp },

    data: () => ({
        customItems: null
    }),

    created(){
        window.scrollTo(0,0);
        this.customItems = this.DATACUSTOM.text.home
    }
}