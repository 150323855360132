var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.categories)?_c('div',{attrs:{"id":"prizes-categories"}},[_c('v-menu',{attrs:{"rounded":'b-lg',"close-on-content-click":false,"bottom":"","origin":"center center","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn-categories"},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.ICONS.mdiMenu)+" ")]),_c('h3',[_vm._v("Categorias")])],1)]}}],null,false,3578778456)},[_c('v-list',_vm._l((_vm.categories),function(categ,i){return _c('v-list-group',{key:i,on:{"click":function($event){_vm.getPrizesFilters({ idcategoria: categ.id_cat }) &&
                                  !categ.subCategories.length}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',{staticClass:"prizes-categ"},[_vm._v(" "+_vm._s(categ.categoria)+" ")])]},proxy:true}],null,true)},_vm._l((categ.subCategories),function(subCateg,j){return _c('v-list-item',{key:j,attrs:{"link":""},on:{"click":function($event){return _vm.getPrizesFilters({
              idcategoria: categ.id_cat,
              idsubcategoria: subCateg.id_cat,
            })}}},[_c('v-list-item-title',{staticClass:"prizes-categ prizes-subcateg"},[_vm._v(" "+_vm._s(subCateg.categoria)+" ")])],1)}),1)}),1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }