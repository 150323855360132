import Regulation from '../../views/regulation/Regulation.vue'

import { mapState, mapActions } from "vuex"
import {
    generateFields,
    transformCustomFieldsSend,
    transformCustomFieldsReceived,
    transformDefaultFieldsSend,
    transformDefaultFieldsReceived
} from "./Fields"
import { validateForm, mask, moneyFormat, getSlugFromRoute } from "@/assets/js/Utils.js"

const mock_panel_config = require('./_mock_panel_config.json')

export default {
    components: { Regulation },

    props: {
        customItems: Object,
    },

    data: () => ({
        validateForm,
        mask,
        form: {},
        fields: [],
        loading: true,
        sendingData: false,
        customItemsRegister: null,
        indicatedBy: ''
    }),

    async created() {
        this.customItemsRegister = this.customItemsRegister ?? this.customItems.register;
        window.scrollTo(0,0)
        if(this.$route.path.includes('painel-configuracoes')){
            this.customItemsRegister = this.customItems
            this.form = mock_panel_config.form
            this.fields = mock_panel_config.fields
            this.loading = false
            return
        }
        if(this.$route.path == `/${this.$route.params.slug}/meus-dados`)
            this.customItemsRegister = this.customItems.my_data
        if(this.$route.path == `/${this.$route.params.slug}/cadastro`)
            this.customItemsRegister = this.customItems.register

        if (this.$route.path == `/${this.$route.params.slug}/cadastro` && 
            !this.establishment.configuracoes.cliente_pode_cadastrar){
            location.href = this.$route.params.slug;
        }
        
        const filiais = await this.getFiliais()
        this.fields = generateFields(this.configs.campos_adicionais, filiais)
        if (this.user) this.fillForm()

        await this.getIndicator();
        this.loading = false
    },

    methods: {
        maskCpfCnpj(value) {
            return this.mask[value == undefined || value.length <= 14 ? 'cpf' : 'cnpj']
        },
        async getIndicator(){
            if(this.$route.params.indicatorId){
                const indicator = await this.MODULOS.client.getClientId(this.$route.params.indicatorId);
                this.indicatedBy =  indicator.cliente.nome; 
            }
        },
        async registerOrUpdate() {
            if(this.sendingData)
                return;
                
            this.sendingData = true;
            let formSend = transformDefaultFieldsSend(this.fields, this.form, moneyFormat)
            formSend.camposAdicionais = transformCustomFieldsSend(this.fields, this.form);
            formSend["indicacao_cliente"] = this.$route.params.indicatorId;
            const resp = await this.MODULOS.client[this.user ? "update" : "register"](
                this.user
                ? { ...formSend, id_cliente: this.user.id_cliente }
                : { ...formSend, origem_cadastro_cliente: "lojadepremios" }
            );
            if (resp.status == 201 || resp.status == 200) {
                if(this.user){
                    await this.actionUserLoadSession()
                    this.$router.push({ path: `/${getSlugFromRoute(this.$route.path)}` })
                }
                else
                    this.$router.push({ path: `/${getSlugFromRoute(this.$route.path)}/login` })

                if(this.user)
                this.$toasted.global.success({ msg: this.user? 'Atualização realizada com sucesso!' : "Cadastro realizado com sucesso!" })
                this.sendingData = false;
            }
            if(resp.status == 403)
                this.$toasted.global.error({ msg: resp.mensagem })
            
            this.sendingData = false;
            
            return resp
        },

        async getFiliais() {
            if (!this.configs.campo_filial) return
            const filiais = await this.MODULOS.establishment.getBranchs()
            return filiais.filiais.map((e) => Object({ value: e.id_filial.toString(), name: e.filial }))
        },

        fillForm() {
            const customFields = this.fields.filter(e => e.additionalField)
            const customFieldsForm = transformCustomFieldsReceived(customFields, this.user.camposAdicionais)
            const defaultFields = this.fields.filter(e => !e.additionalField)
            this.form = { ...customFieldsForm, ...transformDefaultFieldsReceived(defaultFields, this.user, this.form) }
        },

        isShowField(field) {
            return (this.configs[field.isShow] || this.configs[field.isShow] == undefined) && !(field.showEdition === false && this.user)
        },

        maskField(field) {
            if (field.mask == 'cpfCnpj'){
                var cleanField = this.form[field.prop]?.replaceAll('.', '')?.replaceAll("/","")?.replaceAll("-","")
                return this.mask[this.form[field.prop] == undefined || cleanField.length == 11 ? 'cpf' : 'cnpj']
            }

            return this.mask[field.mask] || this.mask.none
        },

        rulesField(field) {
            // if (this.fieldsNotRequired.includes(field.prop)) return
            if (field.prop == 'confSenha')
                return [this.validateForm.confirmPassword(this.form.senha, this.form.confSenha)]

            return this.validateForm[field.mask || 'required']
        },
        
        ...mapActions(["actionUserLoadSession"])
    },

    computed: {
        ...mapState(["establishment", "user"]),

        configs() {
            return this.user ? this.establishment.meusDados : this.establishment.configuracoes
        },
    },

    watch: {
        customItems: {
            handler: function(val){
                this.customItemsRegister = val
                this.form = mock_panel_config.form
                this.fields = mock_panel_config.fields
                this.loading = false
            }
        }
    }
};