import DeliveryAddressList from './delivery_address_list/DeliveryAddressList.vue';
import DeliveryAddressForm from './delivery_address_form/DeliveryAddressForm.vue';

export default {
    components: { DeliveryAddressList, DeliveryAddressForm },

    data: () => ({
        deliveryAddressesModalId: "",
        componentToShow: "list",
        addressToUpdate: null,
    }),

    async created() {
        this.deliveryAddressesModalId = `dlvry-addr-modal-${Math.round(Math.random() * 100)}`;
    },

    methods: {
        showComponent(component) {
            this.componentToShow = component;
        },

        updateAddress(address) {
            this.addressToUpdate = address;
        },

        deliveryAddressUpdated() {
            this.$emit("delivery-address-updated");
        },
    }
}