import Prizes from "../../components/prizes/Prizes.vue"

export default {
    components: { Prizes },

    data: () => ({
        customItemsPrizes: {},
        loading: false
    }),

    created(){
        window.scrollTo(0,0);
        this.loading = !this.loading
        this.customItemsPrizes = this.DATACUSTOM.text
        this.loading = !this.loading
    }
}