export default {
  props: {
    color: String
  },

  model: { prop: 'color' },

  computed: {
    valores: {
      get() {
        return this.color
      },
      set(newValue) {
        this.$emit('input', newValue)
      }
    }
  }
};