import Register from '../../register/Register.vue'

export default {
    components: { 
        Register
    },

    data: () => ({
        componentSelected: null,
        customItemsMyData: {}
    }),

    created(){
        this.customItemsMyData = this.DATACUSTOM.text
    },

    methods: {
        selectComponent() {
            this.componentSelected = Register
            this.$root.$emit('selectComponent', {
                componentSelected: this.componentSelected,
                customItems: this.customItemsMyData.my_data
            })
        }
    }
}