import { getSlugFromRoute } from "@/assets/js/Utils"
import router from '@/router';
export default {
    data: () => ({
        estabelecimento: null,
    }),
    created()  {
        window.scrollTo(0, 0)  
        this.findBlocked()
    },
    
    methods:{
        async findBlocked (){
            const resp = await this.MODULOS.establishment.getEstablishmentBlocked(this.getSlugBySiteUrl() ?? getSlugFromRoute(router.history._startLocation))
            if (resp.status == 200) {
                this.estabelecimento = resp.estabelecimento;
                document.title = this.estabelecimento.estabelecimento
            }
        },
        getSlugBySiteUrl() {
            var urlHost = location.host;
            var splitedHost = urlHost.split('.');
            var slug = ''
            if (splitedHost.length > 2) {
                slug = splitedHost[1];
            } else {
                slug = splitedHost[0];
            }
            if (slug.includes('donuz') || slug.includes('localhost') || slug.includes('v2')) {
                return null;
            }
            return slug;
        },
    }
}