import PrizesCategories from '../prizesCategories/PrizesCategories.vue'
import PrizesRedemption from '../prizesRedemption/PrizesRedemption.vue'

import { formatPrizePoints } from '../../../../assets/js/Utils.js'
import { mapState } from 'vuex'

export default {
    components: { PrizesCategories, PrizesRedemption },

    props: {
        customItems: Object,
        homePage: Boolean
    },

    data: () => ({
        prizes: [],
        redemptionPrize: null,
        searchPrize: '',
        formatPrizePoints,
        prizesOfPage: [],
        customItemsPrizes: null,
        loading: false
    }),

    created() {
        this.loading = !this.loading
        if (!this.$route.path.includes('painel-configuracoes'))
            window.scrollTo(0, 0)

        if (this.homePage)
            this.customItemsPrizes = this.customItems.home.prizes
        else
            this.customItemsPrizes = this.customItems.prizes

        this.getPrizes()
        this.orderPrizes(this.prizes)
        this.loading = !this.loading
    },

    methods: {
        scrollTop(e) {
            window.scrollTo({
                top: e.target.getBoundingClientRect().top - 15,
                left: 0,
                behavior: 'smooth'
            })
        },
        async getPrizesFilters(filter) {
            if (filter.premio == '') return
            
            const resp = await this.MODULOS.prize.getFilters(filter)
            this.prizes = resp.premios
        },

        async getPrizes() {
            var resp = []
            if (this.$route.path == '/' || this.customItemsPrizes.label) {
                resp = await this.MODULOS.prize.getFilters({ tag: 'destaque' })              
                if(resp.total > 0){
                    this.prizes = resp.premios.slice(0, 3)
       
                    if (this.prizes.length >= 3) {
                        return
                    }
                }
               
                resp = await this.MODULOS.prize.getAll()
                this.prizes = resp.premios.slice(0, 3)

                return;
            }
            resp = await this.MODULOS.prize.getAll()
            this.prizes = resp.premios

        },

        orderPrizes(prizes) {
            if (!prizes) return
            switch (this.establishment.configuracoes.ordem_exibicao_produtos) {
                case 'precoDonuz':
                    prizes = prizes.sort(el => el.precoDonuz)
                    break
                case 'precoDonuz DESC':
                    prizes = prizes.sort((a, b) => a.precoDonuz + b.precoDonuz)
                    break
                case 'produto':
                    prizes = prizes.sort(el => el.produto)
                    break
            }
        }
    },

    computed: {
        prizesPerPage() {
            return parseInt(this.establishment.configuracoes.premios_por_pagina)
        },

        ...mapState(['establishment']),
    },

    watch: {
        searchPrize: function (val) {
            if (val == '') this.getPrizes()
        },

        customItems: {
            handler: function () {
                this.customItemsPrizes = this.customItems.prizes
                this.getPrizes()
            }
        }
    }
}