import Vue from 'vue';

// JS CONTEXT
window.DATACUSTOM = require('../../data.json');
window.DEFAULT_TEXT = require('../../data.json').text;
window.MODULOS = require('../donuz/Modulos').donuz;
window.ICONS = require('../assets/js/Icons').icons;

// VUE CONTEXT
Vue.prototype.DATACUSTOM = window.DATACUSTOM;
Vue.prototype.DEFAULT_TEXT = window.DEFAULT_TEXT;
Vue.prototype.MODULOS = window.MODULOS;
Vue.prototype.ICONS = window.ICONS;


