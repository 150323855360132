import HowItWorks from '../../how_it_works/HowItWorks.vue'

export default {
    components: { 
        HowItWorks
    },

    data: () => ({
        componentSelected: null,
        customItemsHowItWorks: {}
    }),

    created(){
        this.customItemsHowItWorks = this.DATACUSTOM.text
    },

    methods: {
        selectComponent() {
            this.componentSelected = HowItWorks
            this.$root.$emit('selectComponent', {
                componentSelected: this.componentSelected,
                customItems: this.customItemsHowItWorks
            })
        }
    }
}