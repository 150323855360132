import PointDonation from "../../point_donation/PointDonation.vue";

export default {
  components: {
    PointDonation,
  },
  props: {
    customItems: Object,
  },
  data: () => ({
    componentSelected: null,
    customItemsPointDonation: {},
  }),
  created() {
    this.customItemsPointDonation = this.DATACUSTOM.text;
  },
  methods: {
    selectComponent() {
      this.componentSelected = PointDonation;
      this.$root.$emit("selectComponent", {
        componentSelected: this.componentSelected,
        customItems: this.customItemsPointDonation,
      });
    },
  },
};
