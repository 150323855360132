import { validateForm } from "../../../../assets/js/Utils.js";

export default {
  data() {
    return {
      validateForm,
      form: {}
    };
  },
  methods: {
    async forgotPassword() {
      const resp = await this.MODULOS.client.forgotPassword({
        email: this.form.email
      });
      if (resp.status === 200) this.$toasted.global.success({ msg: "Email enviado com sucesso!" });
      return resp;
    },
  },
};