export default {
  props: ["itemsPerPage", "items", "value", "idScrollElem"],

  data: () => ({
    page: 1,
  }),

  created(){
    this.changeItems()
  },
  
  watch: {
    items() {
      this.page = 1;
      this.changeItems()
    },

    page() {
      document.getElementById(this.idScrollElem).scrollIntoView()
      this.changeItems()
    },
  },

  methods: {
    changeItems() {
      if (!this.items) return null
      let result = []
      let count = this.page * this.itemsPerPage - this.itemsPerPage
      let delimiter = count + this.itemsPerPage
      for (let i = count; i < delimiter; i++) {
        if (this.items[i] != null) result.push(this.items[i])
        count++
      }
      this.$emit("input", result)
    }
  },

  computed: {
    totalPages() {
      return this.items ? Math.ceil(this.items.length / this.itemsPerPage) : 1
    },
  },
};