import Ranking from "../../components/ranking/Ranking.vue";

export default {
    components: { Ranking },

    data: () => ({
      
        loading: false
    }),

    created(){
        window.scrollTo(0,0);
    }
}