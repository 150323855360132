import ContactUs from "../../components/contact_us/ContactUs.vue"

export default {
    components: { ContactUs },

    data: () => ({
        customItemsContactUs: {},
        loading: false
    }),

    created(){
        this.loading = !this.loading
        this.customItemsContactUs = this.DATACUSTOM.text
        this.loading = !this.loading
    }
}