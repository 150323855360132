import { mapState } from "vuex"
import { formatPrizePoints, formatCep, formatTelefone } from '../../../assets/js/Utils.js'
import { Printd } from 'printd'
import { print } from './print.json'

export default {
    props: {
        customItems: Object
    },

    data: () => ({
        voucher: null,
        loading: false
    }),

    async created() {
        window.scrollTo(0,0)
        this.loading = !this.loading
        var resp = await this.MODULOS.redemption.getRedemption(this.$route.params.idVoucher)
        if(resp.status == 410){
            resp = await this.MODULOS.gifts.getAllGifts()
            resp = resp.presentes.sort((a, b) => new Date(a.data).getTime() < new Date(b.data).getTime() ? 1 : -1)     
            resp.forEach(element => {
                if(element.id_presente_resgate == this.$route.params.idVoucher)
                    this.voucher = element
            })
            this.loading = !this.loading
            return;
        }
        this.voucher = resp.resgate
        this.loading = !this.loading
    },

    methods: {
        print() {
            const doc = new Printd();
            doc.print(document.getElementById('print'), [print])
        },
        formatPrizePoints,
        formatTelefone,
        getVoucherAddressSummary(voucher) {
            const {rua, numero, complemento, bairro, cidade, estado, cep, pais} = voucher;
            return `${rua}, ${numero} - ${complemento} - ${bairro}, ${cidade} - ${estado}, ${pais}, ${formatCep(cep)}`;
        },
    },

    computed: {
        ...mapState(["establishment", "user"]),
    },
};