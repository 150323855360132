import MyRedemptions from '../../my_redemptions/MyRedemptions.vue'

export default {
    components: { 
        MyRedemptions
    },

    data: () => ({
        componentSelected: null,
        customItemsMyRedemptions: {}
    }),

    created(){
        this.customItemsMyRedemptions = this.DATACUSTOM.text
    },

    methods: {
        selectComponent() {
            this.componentSelected = MyRedemptions
            this.$root.$emit('selectComponent', {
                componentSelected: this.componentSelected,
                customItems: this.customItemsMyRedemptions
            })
        }
    }
}