import Login from '../../login/Login.vue'

export default {
    components: { 
        Login
    },

    data: () => ({
        componentSelected: null,
        customItemsLogin: {}
    }),

    created(){
        this.customItemsLogin = this.DATACUSTOM.text
    },

    methods: {
        selectComponent() {
            this.componentSelected = Login
            this.$root.$emit('selectComponent', {
                componentSelected: this.componentSelected,
                customItems: this.customItemsLogin
            })
        }
    }
}