import Regulation from '../../regulation/Regulation.vue'

export default {
    components: { 
        Regulation
    },

    data: () => ({
        componentSelected: null,
        customItemsRegulation: {}
    }),

    created(){
        this.customItemsRegulation = this.DATACUSTOM.text
    },

    methods: {
        selectComponent() {
            this.componentSelected = Regulation
            this.$root.$emit('selectComponent', {
                componentSelected: this.componentSelected,
                customItems: this.customItemsRegulation
            })
        }
    }
}