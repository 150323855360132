import MyGifts from "../../components/my_gifts/MyGifts.vue"

export default {
    components: { MyGifts },

    data: () => ({
        customItemsMyGifts: {},
        loading: false
    }),

    async created() {
        window.scrollTo(0,0);
        this.loading = !this.loading
        this.customItemsMyGifts = this.DATACUSTOM.text
        this.loading = !this.loading
    }
}