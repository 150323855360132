var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('Loading'):_c('div',{attrs:{"id":"prizes-customer-category"}},[_c('div',{staticClass:"title",class:{
      'title-prizes':
        _vm.$route.path == ("/" + (this.$route.params.slug) + "/premios") ||
        !_vm.customItemsPrizes.label,
    }},[_c('h3',[_vm._v(_vm._s(_vm.customItemsPrizes.titles[0].value))]),_c('h1',[_vm._v(_vm._s(_vm.customItemsPrizes.titles[1].value))])]),_c('div',{staticClass:"user-category"},[_c('strong',[_vm._v(_vm._s(_vm.user.primeiro_nome))]),_c('strong',{staticClass:"separator"},[_vm._v(_vm._s(_vm.userHasCategory() ? "-" : ""))]),_c('strong',[_vm._v(_vm._s(_vm.userHasCategory() ? ("Categoria: " + (_vm.user.categoria.titulo)) : ""))])]),(_vm.showCategoryFilter())?_c('div',{directives:[{name:"show",rawName:"v-show",value:(
      _vm.$route.path != ("/" + (this.$route.params.slug) + "/") &&
      !_vm.customItemsPrizes.label
    ),expression:"\n      $route.path != `/${this.$route.params.slug}/` &&\n      !customItemsPrizes.label\n    "}],staticClass:"categories-filter"},[_c('PrizesCategories',{attrs:{"getPrizesFilters":_vm.getPrizesFilters}}),_c('v-text-field',{staticClass:"search-prize",attrs:{"label":"Pesquise por um prêmio","append-icon":_vm.ICONS.mdiMagnify,"solo":""},on:{"focus":_vm.scrollTop,"input":function($event){return _vm.getPrizesFilters({ premio: _vm.searchPrize })}},model:{value:(_vm.searchPrize),callback:function ($$v) {_vm.searchPrize=$$v},expression:"searchPrize"}})],1):_vm._e(),_c('div',{staticClass:"prizes-initial"},[_vm._l((_vm.prizesOfPage),function(item,i){return _c('v-col',{key:i,staticClass:"col-prizes",class:{
        'single-prize-painel': _vm.$route.path.includes('painel-configuracoes'),
      },attrs:{"cols":"12"}},[_c('Modal',{attrs:{"width":"800","title":'Resgate'}},[(_vm.prizes)?_c('v-card',{staticClass:"single-prize btn-redemption",attrs:{"slot":"clickable"},on:{"click":function($event){_vm.redemptionPrize = item}},slot:"clickable"},[_c('v-col',{attrs:{"cols":"12","sm":"3","md":"2"}},[_c('img',{attrs:{"loading":"lazy","src":item.imagemPrincipal || _vm.defaultImgUrl,"alt":item.produto}})]),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"8"}},[_c('h4',{staticClass:"name-prize"},[_vm._v(_vm._s(item.produto))]),_c('v-progress-linear',{staticClass:"prize-progbar",attrs:{"value":_vm.calculateProgressBar(item),"height":"25"}},[_c('strong',[_vm._v(_vm._s(_vm.getPercentageFromProgressBar(item))+"%")])])],1),_c('v-col',{staticClass:"prize-points",attrs:{"cols":"12","sm":"3","md":"2"}},[_c('strong',{staticClass:"value-prize"},[_vm._v(" "+_vm._s(_vm.formatPrizePoints(item, _vm.establishment).split(" ")[0])+" ")]),_c('span',[_vm._v("pontos")])])],1):_vm._e(),_c('div',{attrs:{"slot":"centent"},slot:"centent"},[_c('PrizesRedemption',{model:{value:(_vm.redemptionPrize),callback:function ($$v) {_vm.redemptionPrize=$$v},expression:"redemptionPrize"}})],1)],1)],1)}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.prizes),expression:"!prizes"}],staticClass:"nothing-prizes"},[_vm._v("Nenhum prêmio foi encontrado")])],2),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.path == ("/" + (this.$route.params.slug))),expression:"$route.path == `/${this.$route.params.slug}`"}],attrs:{"to":("/" + (this.$route.params.slug) + "/premios")}},[_c('v-btn',{staticClass:"btn-more-prizes"},[_vm._v(" Mais prêmios "),_c('v-icon',{staticClass:"icon-more-prizes"},[_vm._v(_vm._s(_vm.ICONS.mdiArrowRight))])],1)],1),_c('Pagination',{attrs:{"itemsPerPage":_vm.prizesPerPage,"items":_vm.prizes,"idScrollElem":"prizes"},model:{value:(_vm.prizesOfPage),callback:function ($$v) {_vm.prizesOfPage=$$v},expression:"prizesOfPage"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }