import { mapState } from "vuex"

export default {
    props: {
        customItems: Object
    },

    data: () => ({
        loading: false,
        redemptions: null,
        redemptionsOfPage: [],
        customItemsMyRedemptions: null
    }),

    async created() {
        if(!this.$route.path.includes('painel-configuracoes'))
            window.scrollTo(0,0)
            
        this.loading = true
        this.customItemsMyRedemptions = this.customItems.my_redemptions
        if(this.user){
            const resp = await this.MODULOS.redemption.getAllRedemptions()
            this.redemptions = resp.resgates.sort((a, b) => new Date(a.data).getTime() < new Date(b.data).getTime() ? 1 : -1)
        }
        this.loading = false
    },

    computed:{
        ...mapState(['user'])
    }
}