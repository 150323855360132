import Prizes from '../../prizes/Prizes.vue'

export default {
    components: { 
        Prizes
    },

    data: () => ({
        componentSelected: null,
        customItemsPrizes: {}
    }),

    created(){
        this.customItemsPrizes = this.DATACUSTOM.text
    },

    methods: {
        selectComponent() {
            this.componentSelected = Prizes
            this.$root.$emit('selectComponent', {
                componentSelected: this.componentSelected,
                customItems: this.customItemsPrizes
            })
        }
    }
}