export const generateFields = (additionalFields, filiais) => [
  { type: "image", mask: "image", col: "12", label: "Foto", isShow: "foto", prop: "foto" },
  { type: "text", mask: "firstName", label: "Primeiro nome", isShow: "primeiro_nome", prop: "primeiro_nome" },
  { type: "text", label: "Sobrenome", isShow: "cadastro_campo_sobrenome", prop: "segundo_nome" },
  { type: "select", label: "Sexo", isShow: "campo_sexo", prop: "sexo", opcoes: [{ name: "Masculino", value: 'm' }, { name: "Feminino", value: 'f' }] },
  { type: "text", mask: "cpfCnpj", label: "CPF / CNPJ", isShow: "cadastro_cpf", prop: "cpf" },
  { type: "data", mask: "date", label: "Data de nascimento", isShow: "cadastro_campo_data_nascimento", prop: "data_nascimento", blockEdit: true },
  { type: "text", mask: "phone", label: "Telefone", isShow: "cadastro_campo_telefone", prop: "telefone" },
  { type: "text", mask: "cellPhone", label: "Celular", isShow: "cadastro_campo_celular", prop: "celular" },
  { type: "text", mask: "email", label: "Email", isShow: "cadastro_campo_email", prop: "email" },
  { type: "select", label: "Escolaridade", isShow: "cadastro_campo_escolaridade", prop: "escolaridade", opcoes: ["Medio", "Técnico", "Graduação", "Pós-Graduação", "Mestrado", "Doutorado", "Pós-Doutorado"] },
  { type: "text", mask: "money", vMoney: {}, name: "money", label: "Renda mensal", isShow: "cadastro_campo_renda_mensal", prop: "renda_mensal" },
  { type: "text", label: "Codigo de cliente", isShow: "cadastro_codigo_cliente", prop: "codigo_cliente" },
  { type: "select", label: "Qual filial você frequenta?", isShow: "campo_filial", prop: "filial", opcoes: filiais },
  { type: "text", label: "Profissão", isShow: "campo_profissao", prop: "profissao" },
  { type: "text", label: "Time do coração", isShow: "campo_time_torce", prop: "time_torce" },
  { type: "select", label: "O que melhor te descreve?", isShow: "campo_tipo_cliente", prop: "tipo_de_cliente", opcoes: [{ name: "Cliente final", value: "cliente_final" }, { name: "Prestador de serviço", value: "prestador_servico" }, { name: "Sou uma empresa", value: "empresa" }] },
  { type: "text", mask: "cep", label: "CEP", isShow: "cadastro_campo_cep", prop: "cep" },
  { type: "text", label: "Endereco", isShow: "cadastro_campo_endereco", prop: "endereco" },
  { type: "text", label: "Numero", isShow: "cadastro_campo_numero", prop: "numero" },
  { type: "text", mask: "state", label: "Estado (sigla)", isShow: "cadastro_campo_estado", prop: "estado" },
  { type: "text", label: "Cidade", isShow: "cadastro_campo_cidade", prop: "cidade" },
  { type: "text", label: "Complemento", isShow: "cadastro_campo_complemento", prop: "complemento" },
  ...generateAdditionalFields(additionalFields),
  { type: "password", mask: "password", label: "Senha", isShow: "cadastro_campo_senha", prop: "senha", showEdition: false },
  { type: "password", label: "Confirmar senha", isShow: "confirmar_senha", prop: "confSenha", send: false, showEdition: false },
];

export const transformCustomFieldsSend = (fields, form) => {
  let objCustomFields = parentCustomFields();
  fields.filter((e) => e.additionalField).forEach((e) => {
    let value = {};
    if (e.type == "checkbox")
      e.opcoes.forEach((child) => {
        if (form[child.value]) value[child.value] = form[child.value]
      });

    if (form[e.prop] || Object.keys(value).length)
      objCustomFields[e.type][e.prop] = form[e.prop] || value;
  });
  return removeEmptyObj(objCustomFields);
};

export const transformDefaultFieldsSend = (defaultFields, form, moneyFormat) => {
  let objDefaultFields = {};
  defaultFields.forEach(e => {
    if (!e.additionalField && e.prop && (e.send != false) && form[e.prop] !== undefined)
      objDefaultFields[e.prop] = (e.name == 'money') ? moneyFormat(form[e.prop]) : form[e.prop];
  });
  return objDefaultFields;
};

export const transformCustomFieldsReceived = (customFields, customFieldsUser) => {
  if (!customFieldsUser) return
  let formCustomFields = {};
  customFields.forEach(e => {
    const field = customFieldsUser[e.type];
    if (!field || !field[e.prop]) return;
    if (e.type == 'checkbox' || e.type == 'select') {
      Object.entries(e.type == 'checkbox' ? field[e.prop] : field).forEach(([key, value]) => {
        formCustomFields[key] = value;
      })
      return
    }
    formCustomFields[e.prop] = field[e.prop];
  });
  return formCustomFields;
}

export const transformDefaultFieldsReceived = (defaultFields, defaultFieldsUser) => {
  let formDefaultFields = {};
  defaultFields.forEach((e) => {
    if (e.prop == 'renda_mensal') defaultFieldsUser[e.prop] = defaultFieldsUser[e.prop]?.toFixed(2);
    formDefaultFields[e.prop] = defaultFieldsUser[e.prop]
  });
  return formDefaultFields;
}

const parentCustomFields = () => Object({
  text: {},
  textarea: {},
  select: {},
  checkbox: {},
  data: {},
});

const generateAdditionalFields = (fields) => {
  let additionalFields = [];
  fields.forEach((el) => {
    if (el.tipo == "checkbox" || el.tipo == "select")
      additionalFields.push(createFieldObj(el, createOptions(el.opcoes)));
    else
      additionalFields.push(createFieldObj(el));
  });
  return additionalFields.sort((el) => (el.type != "checkbox" ? -1 : 1));
};

const createFieldObj = (el, opcoes) =>
  Object({
    type: el.tipo,
    label: el.campo,
    additionalField: true,
    prop: el.id_campo_adicional,
    opcoes,
    mask: 'required'
  });

const createOptions = (options) =>
  options.map((e) =>
    Object({ value: e.id_campo_adicional_opcao.toString(), name: e.opcao })
  );

const removeEmptyObj = (obj) => {
  let newObj = {};
  Object.entries(obj).forEach(([key, value]) => {
    if (Object.keys(value).length > 0) newObj[key] = value
  });
  return newObj;
}
