export default {
  props: {
    title: String,
    subtitle: String,
    width: String,
    isCloseIcon: {
      default: true,
      type: Boolean,
    },
    isCloseButton: {
      default: false,
      type: Boolean,
    },
    minHeigth: {
      type: String,
    },
  },

  data: () => ({
    dialog: false,
  })
};