import PrizesDefaultModel from "./prizesDefaultModel/PrizesDefaultModel.vue";
import PrizesCustomerCategoryModel from "./prizesCustomerCategoryModel/PrizesCustomerCategoryModel.vue";
import { mapState, mapActions } from 'vuex';

export default {
    components: { PrizesDefaultModel, PrizesCustomerCategoryModel },

    props: {
        customItems: Object,
        homePage: Boolean
    },

    methods: {
        useCustomerCategoryModel() {
            const userIsLogged = !!this.user;
            const contractedResource = this.establishment.contractedResources?.includes("premios_por_categoria_cliente__");
            const configEnabled = this.establishment.configuracoes?.premios_por_categoria_cliente;
            return userIsLogged && contractedResource && configEnabled;
        },
        ...mapActions(["actionSetDeliveryAddresses"])
    },

    async created() {
        await this.actionSetDeliveryAddresses();
    },

    computed: {
        ...mapState(["establishment", "user"]),
    },
}