import { mapState } from 'vuex'

export default {
    data: () => ({
        loading: false,
        ranking: [],
        rankingOfPage: [],
        page: 1
    }),

    async created() {
        window.scrollTo(0,0)
        this.loading = true

        if(this.user){
          const resp = await this.MODULOS.points.getRanking()
          this.ranking = this.addUserToRanking(resp.ranking);
        }
        this.loading = false
      },
      computed: {
        ...mapState(["user","establishment"])
      },
    methods: {
      formatCurrency(value){
        return isNaN(value) ? value : (+value).toFixed(2);
      },
      addUserToRanking(ranking) {
        const userIsInRanking = ranking.find(
          (user) => user.cliente_id == this.user.id_cliente
        );
        if (!userIsInRanking) {
          ranking.push({
            cliente_id: this.user.id_cliente,
            primeiro_nome: this.user.primeiro_nome,
            foto: this.user.foto,
            pontuacao_anual: "-",
            position: "",
          });
        }
        return ranking;
      },
    }
}