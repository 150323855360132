import ScoringCode from '../../scoring_code/ScoringCode.vue'

export default {
    components: { 
        ScoringCode
    },
    props: {
        customItems: Object,
    },
    data: () => ({
        componentSelected: null,
        customItemsScoringCode: {}
    }),
    created() {
        this.customItemsScoringCode = this.DATACUSTOM.text
    },
    methods: {
        selectComponent() {
            this.componentSelected = ScoringCode
            this.$root.$emit('selectComponent', {
                componentSelected: this.componentSelected,
                customItems: this.customItemsScoringCode
            })
        }
    }
}