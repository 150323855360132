import {
    mdiAccountPlusOutline,
    mdiCurrencyUsd,
    mdiGiftOutline,
    mdiArrowRight,
    mdiMenu,
    mdiCardSearchOutline,
    mdiPhone,
    mdiEmailOutline,
    mdiMapMarker,
    mdiEye,
    mdiEyeOutline,
    mdiEyeOff,
    mdiCloseCircleOutline,
    mdiMinusCircleOutline,
    mdiPlusCircle,
    mdiAccountCircleOutline,
    mdiMenuDown,
    mdiChevronRight,
    mdiContentCopy,
    mdiAccountCircle,
    mdiCog,
    mdiHelpCircleOutline,
    mdiAccount,
    mdiCash,
    mdiPaletteOutline,
    mdiTrashCan,
    mdiAlertCircle,
    mdiFormatColorHighlight,
    mdiMagnify,
    mdiViewList,
    mdiVideo,
    mdiWhatsapp,
    mdiStar,
    mdiCurrencyUsdOff
} from '@mdi/js';

export const icons = {
    mdiAccountPlusOutline,
    mdiCurrencyUsd,
    mdiGiftOutline,
    mdiArrowRight,
    mdiMenu,
    mdiCardSearchOutline,
    mdiPhone,
    mdiEmailOutline,
    mdiMapMarker,
    mdiEye,
    mdiEyeOutline,
    mdiEyeOff,
    mdiCloseCircleOutline,
    mdiMinusCircleOutline,
    mdiPlusCircle,
    mdiAccountCircleOutline,
    mdiMenuDown,
    mdiChevronRight,
    mdiContentCopy,
    mdiAccountCircle,
    mdiCog,
    mdiHelpCircleOutline,
    mdiAccount,
    mdiCash,
    mdiPaletteOutline,
    mdiTrashCan,
    mdiAlertCircle,
    mdiFormatColorHighlight,
    mdiMagnify,
    mdiViewList,
    mdiVideo,
    mdiWhatsapp,
    mdiStar,
    mdiCurrencyUsdOff
};