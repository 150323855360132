var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('Loading'):_c('div',[_c('div',{class:{ 'overflow-nav-bar': _vm.$vuetify.breakpoint.width < 1115 },attrs:{"id":"navbar"}},[_c('div',{class:{'d-none' : _vm.$route.path.includes('painel-configuracoes')},attrs:{"id":"close-navbar-ios"},on:{"click":function($event){_vm.showBarMobile = false}}}),_c('div',{staticClass:"items-navbar",class:{ 'items-nav-bar-logged': _vm.user }},[_c('div',{staticClass:"img"},[_c('router-link',{attrs:{"to":("/" + (this.$route.params.slug))}},[_c('img',{attrs:{"loading":"lazy","src":_vm.establishment.logo}})])],1),_c('div',{staticClass:"icon-menu d-none"},[_c('v-btn',{staticClass:"btn-icon",attrs:{"id":"iconMenu"},on:{"click":function($event){$event.stopPropagation();_vm.showBarMobile = !_vm.showBarMobile}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.ICONS.mdiMenu)+" ")])],1)],1),_c('div',{staticClass:"nav-bar",class:{ 'open-bar': _vm.showBarMobile }},[_c('div',{staticClass:"items-default",class:{ 'items-default-logged': _vm.user }},_vm._l((_vm.itemsNavbar.itemsDefault),function(item,i){return _c('router-link',{key:i,attrs:{"to":_vm.$route.path != '/painel-configuracoes'
                ? '/' + _vm.$route.params.slug + item.to
                : ''},nativeOn:{"click":function($event){_vm.showBarMobile = !_vm.showBarMobile}}},[_vm._v(" "+_vm._s(item.text)+" ")])}),1),(_vm.itemsNavbar.itemsCustom.length > 0)?_c('div',{staticClass:"items-custom"},[(_vm.$vuetify.breakpoint.width > 1115)?_c('v-menu',{attrs:{"open-on-hover":"","close-on-content-click":true,"rounded":'b-lg',"bottom":"","origin":"center center","transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn-items-custom"},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.ICONS.mdiMenu))]),_c('v-icon',[_vm._v(_vm._s(_vm.ICONS.mdiMenuDown))])],1)]}}],null,false,4224508789)},[_c('v-list',{staticClass:"menu-items-custom"},_vm._l((_vm.itemsNavbar.itemsCustom),function(item,index){return _c('v-list-item',{key:index,attrs:{"link":""}},[_c('a',{attrs:{"href":_vm.$route.path != '/painel-configuracoes' ? item.to : '',"target":"_blank"}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.text)+" ")])],1)])}),1)],1):_vm._l((_vm.itemsNavbar.itemsCustom),function(item,index){return _c('a',{key:index,attrs:{"href":item.to,"target":"_blank"}},[_vm._v(" "+_vm._s(item.text)+" ")])})],2):_vm._e(),(!_vm.isLogged)?_c('div',{staticClass:"buttons"},[_c('router-link',{attrs:{"to":_vm.$route.path != '/painel-configuracoes'
                ? '/' + _vm.$route.params.slug + '/login'
                : ''}},[_c('v-btn',{staticClass:"login",on:{"click":function($event){_vm.showBarMobile = !_vm.showBarMobile}}},[_vm._v("Login")])],1),(_vm.establishment.configuracoes.cliente_pode_cadastrar)?_c('router-link',{attrs:{"to":_vm.$route.path != '/painel-configuracoes'
                ? '/' + _vm.$route.params.slug + '/cadastro'
                : ''}},[_c('v-btn',{staticClass:"register",on:{"click":function($event){_vm.showBarMobile = !_vm.showBarMobile}}},[_vm._v("Cadastrar")])],1):_vm._e()],1):_vm._e(),(_vm.isLogged)?_c('div',{staticClass:"menu-logged"},[(_vm.$vuetify.breakpoint.width > 1115)?_c('v-menu',{attrs:{"close-on-content-click":true,"rounded":'b-lg',"bottom":"","origin":"center center","transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.ICONS.mdiAccountCircleOutline))]),_c('h3',[_vm._v("Olá, "+_vm._s(_vm.user.primeiro_nome == null ? '': _vm.user.primeiro_nome))]),_c('v-icon',[_vm._v(_vm._s(_vm.ICONS.mdiMenuDown))])],1)]}}],null,false,3395837290)},[_c('MenuLogged')],1):_c('MenuLogged')],1):_vm._e()])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
      _vm.$route.name == 'Home' || _vm.$route.path.includes('/painel-configuracoes')
    ),expression:"\n      $route.name == 'Home' || $route.path.includes('/painel-configuracoes')\n    "}],staticClass:"banner"},[_c('Banner')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }