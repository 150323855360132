import { mapState } from 'vuex'

export default{

    props: {
        customItems: Object,
        homePage: Boolean
    },
    
    data: () => ({
        customItemsBannerApp: null,
        linkPlayStore: null,
        linkAppStore: null,
        availableSoon: false,
        showBannerApp: false
    }),

    async created() {
        window.scrollTo(0,0);
        // Pegar as configs personalizada
        if(this.homePage)
            this.customItemsBannerApp = this.customItems.home.banner_app
        else
            this.customItemsBannerApp = this.customItems.banner_app
        

        var resp = await this.MODULOS.establishment.settingsApp();

        this.showBannerApp = resp.meus_pontos_habilitado;

        this.linkAppStore = 'https://itunes.apple.com/ca/app/meus-pontos-cartao-fidelidade/id969192544?mt=8';
        this.linkPlayStore = 'https://play.google.com/store/apps/details?id=br.com.ioasys.donuz';

        if(this.establishment.configuracoes.url_android || this.establishment.configuracoes.url_ios){
            this.linkPlayStore = this.establishment.configuracoes.url_android
            this.linkAppStore = this.establishment.configuracoes.url_ios
            this.showBannerApp = true
        }
    },

    methods:{
        openModal(store) {
            if(store == 'play')
                if (!this.linkPlayStore) this.availableSoon = true
            
            if(store == 'app')
                if (!this.linkAppStore) this.availableSoon = true
        }
    },

    computed: {
        ...mapState(["establishment"])
    }
}