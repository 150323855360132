import MyPoints from '../../my_points/MyPoints.vue'

export default {
    components: { 
        MyPoints
    },

    data: () => ({
        componentSelected: null,
        customItemsMyPoints: {}
    }),

    created(){
        this.customItemsMyPoints = this.DATACUSTOM.text
    },

    methods: {
        selectComponent() {
            this.componentSelected = MyPoints
            this.$root.$emit('selectComponent', {
                componentSelected: this.componentSelected,
                customItems: this.customItemsMyPoints
            })
        }
    }
}