import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdiSvg',
    },
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: "#7F56D9",
                primaryText: "#161C2D",
                secondary: "#A88BE6",
                secondaryText: "#6A6A80",
                colorTextMenu: "#161C2D",
                backgroundMenu: "#FFFFFF",
                background: "#F9F5FF",
                backgroundFooterLight: "#D0C0F2",
                backgroundFooterDark: "#42307D"
            },
        },
    },
});
