import Navbar from '../../navbar/Navbar.vue'

export default {
    components: { 
        Navbar
    },

    data: () => ({
        componentSelected: null,
        customItemsNavbar: {}
    }),

    created(){
        this.customItemsNavbar = this.DATACUSTOM.text
    },

    methods: {
        selectComponent() {
            this.componentSelected = Navbar
            this.$root.$emit('selectComponent', {
                componentSelected: this.componentSelected,
                customItems: this.customItemsNavbar,
                home: false
            })
        },
        addItem(){
            this.customItemsNavbar.navbar.itemsCustom.push({
                placeholder: "Escolha um nome para página personalizada",
                text: "",
                placeholder_link: "Informe o link da página",
                to: ""
            })
        },
        removeItem(key){
            this.customItemsNavbar.navbar.itemsCustom.splice(key,1)
        }
    }
}