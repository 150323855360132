import Voucher from "../../components/voucher/Voucher.vue"

export default {
    components: { Voucher },

    data: () => ({
        customItemsVoucher: {},
        loading: false
    }),

    async created() {
        window.scrollTo(0,0);
        this.loading = !this.loading
        this.customItemsVoucher = this.DATACUSTOM.text.voucher
        this.loading = !this.loading
    }
}