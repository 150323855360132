import PrizesCategories from "../prizesCategories/PrizesCategories.vue";
import PrizesRedemption from "../prizesRedemption/PrizesRedemption.vue";

import { formatPrizePoints } from "../../../../assets/js/Utils.js";
import { mapState, mapActions } from "vuex";

export default {
  components: { PrizesCategories, PrizesRedemption },

  props: {
    customItems: Object,
    homePage: Boolean,
  },

  data: () => ({
    prizes: [],
    redemptionPrize: null,
    searchPrize: "",
    formatPrizePoints,
    prizesOfPage: [],
    customItemsPrizes: null,
    loading: false,
    test: 80,
    defaultImgUrl:
      "https://s3-sa-east-1.amazonaws.com/static.donuz.co/resources/uploads/lojaDePremios/premioImagem/202103031317591096811739.jpg",
    userBalance: 0,
  }),

  created() {
    this.loading = !this.loading;
    if (!this.$route.path.includes("painel-configuracoes"))
      window.scrollTo(0, 0);

    if (this.homePage) this.customItemsPrizes = this.customItems.home.prizes;
    else this.customItemsPrizes = this.customItems.prizes;

    this.getPrizes();
    this.orderPrizes(this.prizes);
    this.loading = !this.loading;
    this.getUserBalance();
  },

  methods: {
    scrollTop(e) {
      window.scrollTo({
        top: e.target.getBoundingClientRect().top - 15,
        left: 0,
        behavior: "smooth",
      });
    },
    async getPrizesFilters(filter) {
      if (filter.premio == "") return;
      const resp = await this.MODULOS.prize.getFilters(filter);
      this.prizes = resp.premios;
    },

    async getPrizes() {
      var resp = [];
      if (this.$route.path == '/' || this.customItemsPrizes.label) {
        resp = await this.MODULOS.prize.getFilters({ tag: 'destaque' })
        if (resp) {
          this.prizes = resp.premios.slice(0, 3)

          if (this.prizes.length >= 3) {
            return
          }
        }

        resp = await this.MODULOS.prize.getAll()
        this.prizes = resp.premios.slice(0, 3)

        return;
      }
      resp = await this.MODULOS.prize.getAll();
      this.prizes = resp.premios;
      this.saveAllowedPrizeCategories();
    },

    saveAllowedPrizeCategories() {
      const allowedCategories = new Set();
      for (const prizeIndex in this.prizes) {
        const currentPrize = this.prizes[prizeIndex];
        allowedCategories.add(currentPrize.categoria);
      }
      this.actionSetAllowedPrizeCategories(Array.from(allowedCategories));
    },

    orderPrizes(prizes) {
      if (!prizes) return;
      switch (this.establishment.configuracoes.ordem_exibicao_produtos) {
        case "precoDonuz":
          prizes = prizes.sort((el) => el.precoDonuz);
          break;
        case "precoDonuz DESC":
          prizes = prizes.sort((a, b) => a.precoDonuz + b.precoDonuz);
          break;
        case "produto":
          prizes = prizes.sort((el) => el.produto);
          break;
      }
    },

    getUserBalance() {
      const balanceAsString = this.user.saldo.trim().split(" ")[0];
      this.userBalance = +balanceAsString
        .replaceAll(".", "")
        .replaceAll(",", ".");
    },

    calculateProgressBar(item) {
      return (this.userBalance / item.precoDonuz) * 100;
    },

    getPercentageFromProgressBar(item) {
      const progressBar = this.calculateProgressBar(item);
      const progressBarPercentage = progressBar < 100 ? progressBar : 100;
      return progressBarPercentage.toFixed(1);
    },

    showCategoryFilter() {
      return this.establishment.configuracoes?.filtro_categ_loja_premios;
    },

    userHasCategory() {
      return ![null, undefined].includes(this.user.categoria.titulo) && this.user.categoria.titulo.trim() != "";
    },

    ...mapActions(["actionSetAllowedPrizeCategories"]),
  },

  computed: {
    prizesPerPage() {
      return parseInt(this.establishment.configuracoes.premios_por_pagina);
    },

    ...mapState(["establishment", "user"]),
  },

  watch: {
    searchPrize: function (val) {
      if (val == "") this.getPrizes();
    },

    customItems: {
      handler: function () {
        this.customItemsPrizes = this.customItems.prizes;
        this.getPrizes();
      },
    },
  },
};
