import MyRedemptions from "../../components/my_redemptions/MyRedemptions.vue"

export default {
    components: { MyRedemptions },

    data: () => ({
        customItemsMyRedemptions: {},
        loading: false
    }),

    async created() {
        window.scrollTo(0,0);
        this.loading = !this.loading
        this.customItemsMyRedemptions = this.DATACUSTOM.text
        this.loading = !this.loading
    }
}