import MyPoints from "../../components/my_points/MyPoints.vue"

export default {
    components: { MyPoints },

    data: () => ({
        customItemsMyPoints: {},
        loading: false
    }),

    async created() {
        window.scrollTo(0,0);
        this.loading = !this.loading
        this.customItemsMyPoints = this.DATACUSTOM.text
        this.loading = !this.loading
    }
}