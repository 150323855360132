import { mapState } from 'vuex'
export default {
    props: {
        customItems: Object,
        routeName: '',
        politicsTitle: ''
    },

    data: () => ({
        loading: false,
        text: ' ',

    }),

    async created() {
        await this.update();
    },

    methods: {
        async update() {
            window.scrollTo(0, 0)
            this.loading = !this.loading
            const resp = await this.MODULOS.establishment.getEstablishmentFields(this.routeName)
            if (resp.estabelecimento[this.routeName])
                this.text = resp.estabelecimento[this.routeName]

            if (!resp.estabelecimento[this.routeName]) {
                if (this.routeName == 'politicaPrivacidade') {
                    this.text = `<a target='__blank' href='https://donuz.co/politica_de_privacidade.php?e=${this.establishment.id_admin}'> Veja a política nesse link</a>`
                }
                else {
                    this.text = `<a target='__blank' href='https://donuz.co/politica_de_cookie.php?e=${this.establishment.id_admin}'> Veja a política nesse link</a>`
                }
            }

            this.loading = !this.loading
        }
    },
    watch: {
        async routeName() {
            await this.update()
        }
    },
    computed: {
        ...mapState(['establishment'])
    }
}