export default {
    data: () => ({
        banners: {}
    }),
    
    async created() {
        window.scrollTo(0,0);
        const resp = await this.MODULOS.establishment.getBanners()

        this.banners = resp.banners.filter(el => !el.descricao.toLowerCase().includes('no-hotsite') &&
                                                 !el.descricao.toLowerCase().includes('secundario'))

        this.banners = this.banners.filter(el => this.$vuetify.breakpoint.width < 800 ? 
                                                !el.descricao.toLowerCase().includes('no-app') : 
                                                !el.descricao.toLowerCase().includes('mobile'))
    }
};