import store from '@/store';
import { challenge } from './challenge-obfuscated';

async function baseRequest(method, route, options) {
  const API_URL = 'https://app.donuz.co/api/';
  const challengeToken = challenge();
  let headers = { ...store.state.headers, "challenge-token": challengeToken };

  let body;
  if (options?.file) {
    body = options?.body;
    delete headers['Content-Type']
  }
  else
    body = options?.body ? JSON.stringify(options.body) : null;

  if (options?.isTokenClient) {
    const user = JSON.parse(sessionStorage.user);
    headers['token-cliente'] = user.token;
  }

  const resp = await fetch(`${API_URL}${route}`, Object.assign({
    method,
    headers
  }, { body } || {}));
  const json = await resp.json();
  if (json.status != 200 && json.status != 201 && json.status && !options?.body.saldo)
    window?.VueContext?.$toasted?.global.error({ ...json, msg: json.mensagem });

  return json;
}

export const get = (route, options) => baseRequest('GET', route, options);
export const post = (route, options) => baseRequest('POST', route, options);
export const put = (route, options) => baseRequest('PUT', route, options);
export const httpDelete = (route, options) => baseRequest('DELETE', route, options);