import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../template/views/home/Home.vue'
import Prizes from '../template/views/prizes/Prizes.vue'
import HowItWorks from '../template/views/how_it_works/HowItWorks.vue'
import Regulation from '../template/views/regulation/Regulation.vue'
import ContactUs from '../template/views/contact_us/ContactUs.vue'
import Register from '../template/views/register/Register.vue'
import Login from '../template/views/login/Login.vue'
import Voucher from '../template/views/voucher/Voucher.vue'
import MyRedemptions from '../template/views/my_redemptions/MyRedemptions.vue'
import MyGifts from '../template/views/my_gifts/MyGifts.vue'
import MyPoints from '../template/views/my_points/MyPoints.vue'
import MyData from '../template/views/my_data/MyData.vue'
import IndicateFriends from '../template/views/indicate_friends/IndicateFriends.vue'
import ChangePassword from '../template/views/change_password/ChangePassword.vue'
import PanelConfig from '../template/views/panel_config/PanelConfig.vue'
import ScoringCode from '../template/views/scoring_code/ScoringCode.vue'
import Ranking from '../template/views/ranking/Ranking.vue'
import {getSlugFromRoute} from "@/assets/js/Utils"
import Policies from '../template/components/policies/Policies.vue'
import PointDonation from "../template/views/point_donation/PointDonation.vue";

// const config = require('../../config.json')
const config = '/'

Vue.use(VueRouter)

const routes = [
  {
    path: '/:slug?',
    name: 'Home',
    component: Home,
    meta: { logged: false }
  },
  {
    path: '/:slug?/premios',
    name: 'Prizes',
    component: Prizes,
    meta: { logged: false }
  },
  {
    path: '/:slug?/como-funciona',
    name: 'HowItWorks',
    component: HowItWorks,
    meta: { logged: false }
  },
  {
    path: '/:slug?/regulamento',
    name: 'Regulation',
    component: Regulation,
    meta: { logged: false }
  },
  {
    path: '/:slug?/politica-privacidade',
    name: 'politica-privacidade',
    component: Policies,
    props: { routeName: 'politicaPrivacidade', politicsTitle : 'Política de privacidade'},
    meta: { logged: false,  }
  },
  {
    path: '/:slug?/politica-cookie',
    name: 'politica-cookie',
    component: Policies,
    props: { routeName: 'politicaCookie' , politicsTitle: 'Política de cookies' },
    meta: { logged: false, }
  },
  {
    path: '/:slug?/fale-conosco',
    name: 'ContactUs',
    component: ContactUs,
    meta: { logged: false }
  },
  {
    path: '/:slug?/cadastro/:indicatorId?',
    name: 'Indicator',
    component: Register,
    meta: { logged: false }
  },
  {
    path: '/:slug?/cadastro',
    name: 'Register',
    component: Register,
    meta: { logged: false }
  },
  
  {
    path: '/:slug?/login',
    name: 'Login',
    component: Login,
    meta: { logged: false }
  },
  {
    path: '/:slug?/login/direct-auth/:token',
    name: 'DirectLogin',
    component: Login,
    meta: { logged: false }
  },
  {
    path: '/:slug?/voucher/:idVoucher',
    name: 'Voucher',
    component: Voucher,
    meta: { logged: true }
  },
  {
    path: '/:slug?/meus-resgates',
    name: 'MyRedemptions',
    component: MyRedemptions,
    meta: { logged: true }
  },
  {
    path: '/:slug?/meus-presentes',
    name: 'MyGifts',
    component: MyGifts,
    meta: { logged: true }
  },
  {
    path: '/:slug?/meus-pontos',
    name: 'MyPoints',
    component: MyPoints,
    meta: { logged: true }
  },
  {
    path: '/:slug?/meus-dados',
    name: 'MyData',
    component: MyData,
    meta: { logged: true }
  },
  {
    path: '/:slug?/ranking',
    name: 'ranking',
    component: Ranking,
    meta: { logged: true }
  },
  {
    path: '/:slug?/alterar-senha',
    name: 'ChangePassword',
    component: ChangePassword,
    meta: { logged: true }
  },
  {
    path: '/:slug?/indique',
    name: 'IndicateFriends',
    component: IndicateFriends,
    meta: { logged: true, indicateFriend: true }
  },
  {
    path: '/:slug?/codigo-pontuavel',
    name: 'ScoringCode',
    component: ScoringCode,
    meta: { logged: true }
  },
  {
    path: '/:slug/painel-configuracoes/:loginToken',
    name: 'PanelConfig',
    component: PanelConfig,
    meta: { logged: false }
  },
  {
    path: '/:slug?/transferir-pontos',
    name: 'PointDonation',
    component: PointDonation,
    meta: { logged: true }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: config.BASE_URL,
  routes: routes
})

router.beforeEach((to, from, next) => {
  if(!to.matched.length){
    next({ path: getSlugFromRoute(to.path) })
    return
  }
  var user = sessionStorage.getItem('user');

  if (to.matched.some(record => record.meta.logged === false) && user ) {
    if (to.path == '/login' || to.path == '/cadastro') {
      next({ path: getSlugFromRoute(from.fullPath) })
      return
    }
    next()
  }
  if (to.path.includes('painel-configuracoes') && !to.params.loginToken) {
    // Split sendo feito para pegar o slug dentro do path
    next({ path: getSlugFromRoute(to.path)  })
    return;
  }

  if (to.matched.some(record => record.meta.logged) && !user) {
    next({ path: getSlugFromRoute(from.fullPath)+ '/login' })
    return;
  }
  next()
})



export default router
