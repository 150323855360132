import Navbar from "../components/navbar/Navbar.vue"
import Footer from "../components/footer/Footer.vue"
import router from '@/router';
import Error from "../components/Error/Error.vue";
import Vue from "vue";
import { mapActions, mapState } from "vuex"
import { getSlugFromRoute } from "@/assets/js/Utils"

export default {
  name: "App",

  components: { Navbar, Footer ,Error},

  data: () => ({
    success: false,
    customConfigs: null
  }),

  async created() {
    const resp = await this.getConfigBySlug()
    document.title = "carregando ..."
    if (!resp || resp.status != 200) {
      this.success = "erro"
      return
    }
    await this.actionGetEstablishmentSettings(resp.estabelecimentos[0])
    await this.actionUserLoadSession()
    await this.getCustomConfigs()

    this.changeFavicon(resp.estabelecimentos[0].favicon,resp.estabelecimentos[0].logo)
    
    this.createTagManager()
   
    document.title = this.establishment.estabelecimento
    
    document.getElementById(
      "platformCSS"
    ).innerHTML = this.establishment.configuracoes.css

    eval(this.establishment.configuracoes.js);
    
    this.success = true
  },
  methods: {
    changeFavicon(icon,logo) {
      const favicon = document.getElementById("favicon");
      if(icon != null){
        favicon.href = icon;
      }else{
        favicon.href = logo;
      }
    },

    createTagManager(){
      let tagManagerId = this.establishment.configuracoes.tag_manager_id;
      
      if(tagManagerId == false){
        return;
      }

      document.getElementById(
        "tagManager"
      ).innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${tagManagerId}');`;
  
      document.getElementById(
        "urlTagManager"
      ).innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${tagManagerId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    },

    async getCustomConfigs() {
      const customConfig = sessionStorage.getItem(`customConfig-${this.getSlugBySiteUrl() ?? router.history._startLocation.split('/')[1]}`)
      if (!customConfig) {
        const resp = await this.MODULOS.configStore.getConfigStore()
        if (resp.status == 200) {
          if(resp.data.text == null){
            resp.data.text = Vue.prototype.DEFAULT_TEXT
          }
          this.customConfigs = resp.data
          Vue.prototype.DATACUSTOM = this.customConfigs
          sessionStorage.setItem(`customConfig-${router.history._startLocation.split('/')[1]}`, JSON.stringify(resp.data))
          this.setTheme(Vue.prototype.DATACUSTOM.colors)
        }
        this.setTheme(Vue.prototype.DATACUSTOM.colors) 
        return
      }
      Vue.prototype.DATACUSTOM = JSON.parse(customConfig)
      this.setTheme(Vue.prototype.DATACUSTOM.colors)
    },

    setTheme(colors) {
      const safariColor = document.getElementById("theme-color-safari");
      this.$nextTick(() => {
        safariColor.content = colors.background;
      });
      this.$vuetify.theme.themes.light.primary = colors.primary
      this.$vuetify.theme.themes.light.primaryText = colors.primaryText
      this.$vuetify.theme.themes.light.secondary = colors.secondary
      this.$vuetify.theme.themes.light.secondaryText = colors.secondaryText
      this.$vuetify.theme.themes.light.colorTextMenu = colors.colorTextMenu
      this.$vuetify.theme.themes.light.backgroundMenu = colors.backgroundMenu
      this.$vuetify.theme.themes.light.background = colors.background
      this.$vuetify.theme.themes.light.backgroundFooterLight = colors.backgroundFooterLight
      this.$vuetify.theme.themes.light.backgroundFooterDark = colors.backgroundFooterDark
    },
    getSlugBySiteUrl() {
      var urlHost = location.host;
      var splitedHost = urlHost.split('.');
      var slug = ''
      if (splitedHost.length > 2){
        slug = splitedHost[1];
      }else{
        slug = splitedHost[0];
      }
      if (slug.includes('donuz') || slug.includes('localhost') || slug.includes('v2') ||  slug.includes('192.168')){
        return null;
      }
      return slug;
    },
    async getConfigBySlug() {
     
      let resp = await this.MODULOS.establishment.findBySlug({
        slug: this.getSlugBySiteUrl() ?? getSlugFromRoute(router.history._startLocation)
      })

      if(this.$route.params.slug == null){
        this.$route.params.slug = 'loyalty';
      }

      if (resp.status == 200) {
        let headers = {
          token: "410d4633ae5741efb2adf25de2c1dbb0",
          estabelecimento: resp.estabelecimentos[0].id_admin,
          'Content-Type': 'application/json'
        }
        this.actionMountHeaders(headers);
        return resp
      }
    },

    ...mapActions(["actionGetEstablishmentSettings", "actionUserLoadSession", "actionGetBalanceCliente", "actionMountHeaders"]),
  },
  computed: {
    ...mapState(['establishment'])
  }
}