import { mapState } from 'vuex'
import { validateForm, mask } from "../../../assets/js/Utils.js"

export default {
    props: {
        customItems: Object,
    },

    data: () => ({
        form: {},
        customItemsContactUs: null,
        validateForm,
        mask
    }),

    created(){
        window.scrollTo(0,0)
        this.customItemsContactUs = this.customItems.contact_us
    },

    methods: {
        async sendEmail() {
            const body = {
                nome: this.form.nome,
                email: this.form.email,
                celular: this.form.celular,
                mensagem: this.form.mensagem,
                assunto: this.form.assunto
            }
            const resp = await this.MODULOS.contact.sendEmail({ ...body })

            if (resp.status == 200)
                this.$toasted.global.success({ msg: "Sua mensagem foi enviada!" })

            return resp
        }
    },

    computed: {
        ...mapState(['establishment'])
    }
}