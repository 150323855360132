import { mapState } from "vuex";
import { validateForm, mask } from "../../../assets/js/Utils.js";

export default {
  props: {
    customItems: Object,
  },

  data: () => ({
    form: {
      cpf: "",
      value: 0,
    },
    validateForm,
    mask,
  }),

  created() {
    window.scrollTo(0, 0);
    this.customItemsPointDonation = this.customItems.customItemsPointDonation;
    if (!this.customItemsPointDonation) {
      this.customItemsPointDonation = this.DEFAULT_TEXT.point_donation;
    }
  },

  methods: {
    async donatePoints() {
      const reqBody = {
        valor: this.form.value,
        cpf: this.form.cpf.replaceAll(".", "").replaceAll("-", ""),
        cpf_origem: this.user.cpf,
      };
      const resp = await this.MODULOS.points.transferPoints(reqBody);
      if (resp.status == 200)
        this.$toasted.global.success({ msg: "Doação realizada com sucesso!" });
      else
        this.$toasted.global.error({ msg: "Erro ao realizar a doação!" });
      return resp;
    },
  },

  computed: {
    ...mapState(["user", "establishment"]),
  },
};
