import { mapState } from 'vuex'
import { validateForm, mask } from "../../../assets/js/Utils.js"

export default {
    props: {
        customItems: Object,
    },

    data: () => ({
        form: {},
        validateForm,
        mask,
        customItemsScoringCode: null,
        rules: [
            v => !!v || 'O Comprovante e obrigatório',
            v => (v && v.size > 0) || 'O Comprovante e obrigatório',
          ]
    }),

    created() {
        window.scrollTo(0, 0)
        this.customItemsScoringCode = this.customItems.scoring_code;
        if (!this.customItemsScoringCode){
            this.customItemsScoringCode = this.DEFAULT_TEXT.scoring_code
        }
    },

    methods: {
        async validateCode() {
            const resp = await this.MODULOS.computableCode.validate({ ...this.form })
            if (resp.status != 200) {
                this.$toasted.global.error({ msg: resp.mensagem });
                return;
            }
            this.$toasted.global.success({ msg: resp.mensagem });
        }
    },
    computed: {
        ...mapState(['establishment'])
    }
}