import Register from "../../components/register/Register.vue"

export default {
    components: { Register },

    data: () => ({
        customItemsMyData: {},
        loading: false
    }),

    created(){
        window.scrollTo(0,0);
        this.loading = !this.loading
        this.customItemsMyData = this.DATACUSTOM.text
        this.loading = !this.loading
    }
}