import ChangePassword from "../../components/change_password/ChangePassword.vue"

export default {
    components: { ChangePassword },

    data: () => ({
        customItemsChangePassword: {},
        loading: false
    }),

    async created() {
        window.scrollTo(0,0);
        this.loading = !this.loading
        this.customItemsChangePassword = this.DATACUSTOM.text
        this.loading = !this.loading
    }
}