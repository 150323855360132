import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState({ storage: window.sessionStorage })],
  state: {
    establishment: null,
    user: null,
    headers: {
      token: "410d4633ae5741efb2adf25de2c1dbb0",
      'Content-Type': 'application/json'
    }
  },
  mutations: {
    setUser: (state, user) => { state.user = user},
    setUserBalance: (state, saldo) => { state.user['saldo'] = saldo },
    setAllowedPrizeCategories: (state, categories) => {state.user['allowedPrizeCategories'] = categories},
    setEstablishment: (state, establishment) => { state.establishment = establishment },
    setHeaders: (state, header) => { state.headers = header },
    setDeliveryAddresses: (state, deliveryAddresses) => { state.user.deliveryAddresses = deliveryAddresses },
    setSelectedDeliveryAddress: (state, selectedDeliveryAddress) => { state.user.selectedDeliveryAddress = selectedDeliveryAddress },
    setDefaultDeliveryAddress: (state, defaultDeliveryAddressId) => { state.user.endereco_entrega_padrao = defaultDeliveryAddressId },
  },
  actions: {
    actionMountHeaders: async ({ commit }, header) => {
      commit('setHeaders', header);
    },
    actionGetUser: async ({ commit, dispatch }, clientId) => {
      const resp = await window.MODULOS.client.getClient(clientId);
      if (resp.status === 200){
        var userData = JSON.parse(sessionStorage.getItem('user'));
        let cliente =  resp.cliente
        userData = {...userData, ...cliente};
        commit('setUser', userData);
      }
      await dispatch('actionGetBalanceCliente');
    },
    actionUserLoadSession: async ({ dispatch }) => {
      let userData = sessionStorage.getItem('user');
      if (userData != undefined) {
        const user = JSON.parse(userData);
        if (user == undefined) {
          return;
        }
        await dispatch('actionGetUser', user.id);
      }
    },
    actionGetBalanceCliente: async ({ commit,dispatch, state }) => {
      let resp = await window.MODULOS.client.getBalance();
      if(resp.erro == 'Cliente não encontrado'){
        await dispatch('actionLogout');
        return;
      }
      resp = resp?.saldo?.replaceAll('.', '')?.replaceAll(',', '.');
      resp = `${parseFloat(resp).toLocaleString('pt-BR')} ${state.establishment.moeda} `;
      commit('setUserBalance', resp);
    },
    actionLogout: async ({ commit }) => {
      sessionStorage.removeItem('user')
      location.reload();
      commit('setUser', null);
    },
    actionGetEstablishmentSettings: async ({ commit }, payload) => {
      const settings = await window.MODULOS.establishment.getSettings();
      const settingsMyData = await window.MODULOS.establishment.getSettingsMyData();
      var emailEstablishment = await window.MODULOS.establishment.getEstablishmentFields('email_loja_premios');
      const contractedResources = (await window.MODULOS.establishment.getContractedResources()).recursos_contratados;
      emailEstablishment = emailEstablishment.estabelecimento
      commit('setEstablishment', { ...payload, ...emailEstablishment, ...settings, meusDados: settingsMyData.configuracoes, contractedResources });
    },
    actionSetAllowedPrizeCategories: ({commit}, payload) => {
      commit("setAllowedPrizeCategories", payload);
    },
    actionSetDeliveryAddresses: async ({commit, state}, forceFetch = false) => {
      let deliveryAddressesIsSetted = Boolean(state.user.deliveryAddresses);
      if(deliveryAddressesIsSetted && !forceFetch) return;

      const deliveryAddresses = await window.MODULOS.deliveryAddresses.get(state.user.id_cliente);
      deliveryAddressesIsSetted = Boolean(deliveryAddresses.enderecos_entrega);
      if(!deliveryAddressesIsSetted) return;
      commit('setDeliveryAddresses', deliveryAddresses.enderecos_entrega);

      const selectedDeliveryAddress = deliveryAddresses.enderecos_entrega.find(
        address => address.id_endereco_entrega == state.user.endereco_entrega_padrao)
        ?? null;
      commit("setSelectedDeliveryAddress", selectedDeliveryAddress);
    },
    actionSetSelectedDeliveryAddress: ({commit}, payload) => {
      commit("setSelectedDeliveryAddress", payload);
    },
    actionSetDefaultDeliveryAddress: ({commit}, payload) => {
      commit("setDefaultDeliveryAddress", payload);
    }
  },
  getters: {
    linkPtsvc: (state) => {
      return `https://pts.vc/usr/${state.establishment.id_admin}/${state.user.id_cliente}`;
    }
  }
});
