import Vue from 'vue'
import { mapState } from 'vuex'
import Navbar from '../navbar/Navbar.vue'
import Home from '../../views/home/Home.vue'
import Footer from '../footer/Footer.vue'
import HeaderPanelConfig from './header_panel_config/HeaderPanelConfig.vue'
import CustomNavbar from './custom_navbar/CustomNavbar.vue'
import CustomHome from './custom_home/CustomHome.vue'
import CustomPrizes from './custom_prizes/CustomPrizes.vue'
import CustomPreview from './custom_preview/CustomPreview.vue'
import CustomHowItWorks from './custom_how_it_works/CustomHowItWorks.vue'
import CustomRegulation from './custom_regulation/CustomRegulation.vue'
import CustomContactUs from './custom_contact_us/CustomContactUs.vue'
import CustomLogin from './custom_login/CustomLogin.vue'
import CustomMyData from './custom_my_data/CustomMyData.vue'
import CustomRegister from './custom_register/CustomRegister.vue'
import CustomIndicateFriends from './custom_indicate_friends/CustomIndicateFriends.vue'
import CustomMyPoints from './custom_my_points/CustomMyPoints.vue'
import CustomMyRedemptions from './custom_my_redemptions/CustomMyRedemptions.vue'
import CustomMyGifts from './custom_my_gifts/CustomMyGifts.vue'
import CustomScoringCode from './custom_scoring_code/CustomScoringCode.vue'
import CustomTheme from './custom_theme/CustomTheme.vue'
import CustomPointDonation from './custom_point_donation/CustomPointDonation.vue'

const themesDefault = require('./_themesDefault.json')

export default {
    components: {
        Navbar,
        Home,
        Footer,
        HeaderPanelConfig,
        CustomNavbar,
        CustomHome,
        CustomPrizes,
        CustomPreview,
        CustomHowItWorks,
        CustomRegulation,
        CustomContactUs,
        CustomLogin,
        CustomMyData,
        CustomRegister,
        CustomIndicateFriends,
        CustomMyPoints,
        CustomMyRedemptions,
        CustomMyGifts,
        CustomTheme,
        CustomScoringCode,
        CustomPointDonation
    },

    data: () => ({
        loading: true,
        isRegistering: false,
        themesDefault: null,
        themesDefaultCompleteData: null,
        themeSelected: null,
        themeCustomColors: null,
        componentSelected: null,
        homePage: false,
        customItems: null,
        configEstablishment: null,
        oldCustomConfig: Vue.prototype.DATACUSTOM,
        showModalMigratePlan: false,
        customPermission: [],
        messageErrorPermission: [],
        errorPermission: [],
        showVideo: false,
        componentNavbar: Navbar,
        componentHome: Home,
        componentFooter: Footer
        
    }),

    async created() {
        if (await this.accessValidation()) {
            this.$toasted.global.error({ msg: 'Você não possui acesso!' })
            this.$router.push({ name: 'Home' })
            return
        }

        this.loadPermissions()
        const customConfig = sessionStorage.getItem(`customConfig-${this.$route.params.slug}`)

        // Se tem na sessionStorage, é pq cliente ja tem alguma configuração cadastrada
        if (customConfig)
            Vue.prototype.DATACUSTOM = await JSON.parse(customConfig)
        else
            this.isRegistering = true

        this.customItems = Vue.prototype.DATACUSTOM.text
        this.themeSelected = Vue.prototype.DATACUSTOM.themeName
        this.themesDefaultCompleteData = themesDefault;
        this.themesDefault = Object.keys(themesDefault)
        this.configEstablishment = this.establishment.meusDados
        this.themeCustomColors = Vue.prototype.DATACUSTOM.colors

        if(sessionStorage.getItem('customColors')){
            this.themesDefault.unshift('Personalizacao')
            this.themeCustomColors = JSON.parse(sessionStorage.getItem('customColors'))
            this.themesDefaultCompleteData.Personalizacao = this.themeCustomColors
        }

        // Escuta eventos dos componentes de customização de texto
        this.$root.$on('selectComponent', (payload) => {
            this.showVideo = false
            this.componentSelected = payload.componentSelected
            if (payload.home) {
                this.customItems.home[payload.key] = payload.customItems[payload.key]
                this.homePage = true
                return
            }
            this.customItems = payload.customItems
            this.homePage = false
        })

        // Escuta eventos dos componentes de customização de cor
        this.$root.$on('customTheme', (payload) => {
            this.themesDefault.unshift(payload.themeName)
            this.themeSelected = payload.themeName
            this.themeCustomColors = payload.colors
        })
        this.loading = false
    },
    computed: {
        ...mapState(['establishment'])
    },
    methods: {
        async accessValidation() {
            const body = {
                tokenClient: Buffer.from(this.$route.params.loginToken,'base64').toString()
            }
            const resp = await this.MODULOS.loginToken.login(body)
            return resp.status != 200;
        },

        async loadPermissions(){
            const resp = await this.MODULOS.establishment.getPermission()
            resp.permissoes.forEach(element => {
                if(element.funcionalidade == 'escolherTemaTemplate' 
                   || element.funcionalidade == 'personalizarTemaTemplate' 
                   || element.funcionalidade == 'personalizarTextoTemplate'
                )
                       this.customPermission.push(element) 
            })
        },

        verifyPermissionActive(newCustom){
            this.messageErrorPermission = []
            this.errorPermission = []

            this.customPermission.forEach(element => {
                if(element.ativo != 1){
                    if(element.funcionalidade == 'escolherTemaTemplate'
                       && this.oldCustomConfig.themeName != newCustom.themeName){
                        this.errorPermission.push(element.funcionalidade)
                        this.messageErrorPermission.push(element.descricao)
                    }
                    if(element.funcionalidade == 'personalizarTemaTemplate'
                       && newCustom.themeName == 'Personalizacao'){
                        this.errorPermission.push(element.funcionalidade)
                        this.messageErrorPermission.push(element.descricao)
                    }
                    if(element.funcionalidade == 'personalizarTextoTemplate' 
                       && JSON.stringify(this.oldCustomConfig.text) != JSON.stringify(newCustom.text)){
                          this.messageErrorPermission.push(element.descricao)
                          this.errorPermission.push(element.funcionalidade)
                    }
                }
            })

            return this.messageErrorPermission.length > 0 
        },

        async saveConfig() {
            let body =
            {
                json: {
                    text: this.customItems,
                    colors: this.themeSelected != 'Personalizacao'
                        ? themesDefault[this.themeSelected]
                        : this.themeCustomColors,
                    themeName: this.themeSelected
                }
            }

            if(this.verifyPermissionActive(body.json)){
                this.showModalMigratePlan = true
                return
            }
            this.themeSelected == 'Personalizacao' ?
                sessionStorage.setItem('customColors', JSON.stringify(this.themeCustomColors)) :
                ''
            if(body.json.text == null || body.json.colors == null){
                this.$toasted.global.error({ msg: "Por favor confira suas configurações uma ou mais configuracoes estão inválidas"})
                    return;
            }
            const response  = this.isRegistering 
                ? await this.registerCustom(body)
                : await this.updateCustom(body)
           
            response.sucess
                ? this.$toasted.global.success({ msg: response.message })
                : this.$toasted.global.error({ msg: response.message })
            
            sessionStorage.clear()
            sessionStorage.setItem(`customConfig-${this.$route.params.slug}`, JSON.stringify(body.json))
        },

        async registerCustom(body) {
            const resp = await this.MODULOS.configStore.postConfigStore(body)
            return {
                message : `Configurações cadastrada ${resp.status == 200? 'com' : 'sem' } sucesso !`,
                sucess: resp.status == 200
            }
        },

        async updateCustom(body) {          
            const resp = await this.MODULOS.configStore.putConfigStore(body)
            return {
                message : `Configurações atualizadas ${resp.status == 200? 'com' : 'sem' } sucesso !`,
                sucess: resp.status == 200 
            }
        },
    },
    watch: {
        themeSelected: {
            handler: function (val) {
                this.$vuetify.theme.themes.light =
                    val == 'Personalizacao'
                        ? this.themeCustomColors
                        : themesDefault[val];
            }
        }
    }
}