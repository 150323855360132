import { mapState, mapActions } from 'vuex'
import { formatPrizePoints } from '../../../../../assets/js/Utils'
import PrizeDetails from '../prize_details/PrizeDetails.vue'

export default {
  props: { value: Object },

  components: { PrizeDetails },

  data: () => ({
    loading: false,
    images: [],
  }),

  watch: {
    value() {
      this.loadImages()
    }
  },

  created() {
    this.loadImages();
  },

  methods: {
    ...mapActions(["actionGetBalanceCliente"]),

    async loadImages() {
      this.images = [this.value.imagemPrincipal]
      const resp = await this.MODULOS.prize.getPrize(this.value.id_produto)
      resp.prize.imagensAdicionais.forEach(e => e != null && this.images.push(e))
    },

    nextRedemptionStep() {
      this.$emit("next-redemption-step");
    },

    formatPrizePoints,

    prizeIsUnavailable(prize) {
      const unavailableStatus = 2;
      const prizeCategoryNotAllowed = this.prizeCategoryNotAllowed(prize);
      return prize.estoque === 0 || prize.status == unavailableStatus || prizeCategoryNotAllowed;
    },

    prizeCategoryNotAllowed(prize) {
      const contractedResource = this.establishment.contractedResources?.includes("premios_por_categoria_cliente__");
      const configEnabled = this.establishment.configuracoes?.premios_por_categoria_cliente;
      const prizeCategoryNotAllowed = !this.user.allowedPrizeCategories?.includes(prize.categoria);
      return contractedResource && configEnabled && prizeCategoryNotAllowed;
    },
  },

  computed: {
    ...mapState(["user", "establishment"]),
  },
};