export default {
    props: {
        getPrizesFilters: Function
    },

    data: () => ({
        categories: []
    }),

    async created() {
        window.scrollTo(0,0)
        await this.getPrizesCategories()
    },

    methods: {
        async getPrizesCategories() {
            const resp = await this.MODULOS.prize.getCategories()
            this.categories = resp.categorias
            await this.getPrizesSubCategories(this.categories || [])
        },

        async getPrizesSubCategories(categories) {
            for (const category of categories) {
                const resp = await this.MODULOS.prize.getSubCategories(category.id_cat)
                this.$set(category, "subCategories", resp.categorias)
            }
        },
    }
}
