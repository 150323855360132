import PointDonation from "../../components/point_donation/PointDonation.vue";

export default {
  components: { PointDonation },

  data: () => ({
    loading: false,
    customItemsPointDonation: {},
  }),

  created() {
    this.loading = !this.loading;
    this.customItemsPointDonation = this.DATACUSTOM.text;
    this.loading = !this.loading;
  },
};
