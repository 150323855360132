export default {
    props: {
        customItems: Object
    },

    data: () => ({
        loading: false,
        text: '',
        customItemsRegulation: null
    }),

    async created(){
        window.scrollTo(0,0)
        this.loading = !this.loading

        const resp = await this.MODULOS.establishment.getEstablishmentFields("regulamento")
        this.text = resp.estabelecimento.regulamento

        this.customItemsRegulation = this.customItems.regulation
        
        this.loading = !this.loading
    }

}