import ScoringCode from "../../components/scoring_code/ScoringCode.vue"
export default {
    components: { ScoringCode },

    data: () => ({
        loading: false,
        customItemsScoringCode: {},
    }),

    created(){
        this.loading = !this.loading
        this.customItemsScoringCode = this.DATACUSTOM.text
        this.loading = !this.loading
    }
}