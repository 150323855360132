import MyGifts from '../../my_gifts/MyGifts.vue'

export default {
    components: { 
        MyGifts
    },

    data: () => ({
        componentSelected: null,
        customItemsMyGifts: {}
    }),

    created(){
        this.customItemsMyGifts = this.DATACUSTOM.text
    },

    methods: {
        selectComponent() {
            this.componentSelected = MyGifts
            this.$root.$emit('selectComponent', {
                componentSelected: this.componentSelected,
                customItems: this.customItemsMyGifts
            })
        }
    }
}