export default {
    props: {
        customItems: Object,
        homePage: Boolean
    },

    data: () => ({
        loading: false,
        text: '',
        customItemsHowItWorks: null
    }),

    async created(){
        window.scrollTo(0,0)
        this.loading = !this.loading

        const resp = await this.MODULOS.establishment.getEstablishmentFields("descricao_programa")
        this.text = resp.estabelecimento.descricao_programa
        
        if(this.homePage)
            this.customItemsHowItWorks = this.customItems.home.how_it_works
        else    
            this.customItemsHowItWorks = this.customItems.how_it_works
        
            this.loading = !this.loading
    },

    watch: {
        customItems: {
             handler: async function(){
                this.customItemsHowItWorks = this.customItems.how_it_works
            }
        }
    }

}