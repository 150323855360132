import { mapState } from "vuex";

export default {
  props: {
    customItems: Object,
  },

  data: () => ({
    loading: false,
    points: null,
    pointsOfPage: [],
    customItemsMyPoints: null,
    distinctMetadata1: [],
    distinctMetadata2: [],
    selectedMetadata1: null,
    selectedMetadata2: null,
    totalBalance: 0,
    filteredBalance: 0,
    notFilledTextMetadata1: "",
    notFilledTextMetadata2: "",
  }),

  async created() {
    window.scrollTo(0, 0);
    this.loading = true;
    this.customItemsMyPoints = this.customItems.my_points;
    if (this.user) {
      const resp = await this.MODULOS.points.getPoints();
      this.points = this.sortPointsByDate(resp.pontos);
      this.totalBalance = this.getTotalBalance();
      this.filteredBalance = this.totalBalance;
      this.notFilledTextMetadata1 = `${this.establishment.configuracoes.nome_metadado_1} não preenchido(a)`;
      this.notFilledTextMetadata2 = `${this.establishment.configuracoes.nome_metadado_2} não preenchido(a)`;
      this.distinctMetadata1 = [
        this.notFilledTextMetadata1,
        ...this.getDistinctValuesFromPoints("metadado_1"),
      ];
      this.distinctMetadata2 = [
        this.notFilledTextMetadata2,
        ...this.getDistinctValuesFromPoints("metadado_2"),
      ];
    }
    this.loading = false;
  },
  computed: {
    ...mapState(["user", "establishment"]),
  },

  methods: {
    sortPointsByDate(points) {
      return points.sort((a, b) =>
        new Date(a.date).getTime() > new Date(b.date).getTime() ? 1 : -1
      );
    },

    getTotalBalance() {
      const balanceAsString = this.user.saldo.trim().split(" ")[0];
      const balance = +balanceAsString.replaceAll(".", "").replaceAll(",", ".");
      return balance;
    },

    getDistinctValuesFromPoints(field) {
      const values = this.points.map((point) => point[field]);
      const distinctValues = values
        .filter(this.getNotEmpty)
        .filter(this.getDistinct);
      return distinctValues;
    },

    getNotEmpty(value) {
      return ![null, undefined].includes(value) && !!value.trim();
    },

    getDistinct(value, index, self) {
      return self.indexOf(value) === index;
    },

    async filterPoints() {
      const metadata1Filters = this.getFilters(
        this.selectedMetadata1,
        "metadado_1",
        this.notFilledTextMetadata1
      );
      const metadata2Filters = this.getFilters(
        this.selectedMetadata2,
        "metadado_2",
        this.notFilledTextMetadata2
      );
      const filters = {
        filters: [...metadata1Filters, ...metadata2Filters],
      };

      let resp;
      if (filters.filters.length === 0)
        resp = await this.MODULOS.points.getPoints();
      else {
        resp = await this.MODULOS.points.getPointsWithFilters(filters);
        resp.pontos = this.filterCreditPoints(resp.pontos);
      }

      this.points = this.sortPointsByDate(resp.pontos);
      this.calculateFilteredBalance();
    },

    getFilters(selectedItem, fieldName, valueWhenItemNotSetted) {
      if (selectedItem === null) return [];

      if (selectedItem == valueWhenItemNotSetted)
        return this.addFilterForNotSettedItem(fieldName);

      return this.generateFilter(fieldName, selectedItem);
    },

    addFilterForNotSettedItem(fieldName) {
      return [
        {
          field: fieldName,
          operator: "=",
          value: "",
        },
      ];
    },

    generateFilter(fieldName, fieldValue) {
      return [
        {
          field: fieldName,
          operator: "=",
          value: fieldValue,
        },
      ];
    },

    filterCreditPoints(points) {
      return points.filter((point) => point.operacao == "C");
    },

    calculateFilteredBalance() {
      this.filteredBalance = this.points.reduce((balance, point) => {
        if (!this.pointGenerateBalance(point)) return balance;
        return balance + point.valor_restante;
      }, 0);
    },

    pointGenerateBalance(point) {
      return new Date(point.data_expira) > new Date();
    },

    formatPointsToShow(pointsAsNumber) {
      return pointsAsNumber.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
      });
    },

    getPointIcon(point) {
      switch (point.operacao) {
        case "D":
          return this.pointIsTransfer(point)
            ? window.ICONS.mdiCurrencyUsd
            : window.ICONS.mdiGiftOutline;
        case "E":
          return window.ICONS.mdiCurrencyUsdOff;
        case "C":
        default:
          return window.ICONS.mdiCurrencyUsd;
      }
    },

    getPointDateDescription(point) {
      if (this.pointIsTransfer(point)) return "Transferido em";
      switch (point.operacao) {
        case "C":
          return "Creditado em";
        case "D":
          return "Resgatado";
        case "E":
          return "Estornado em";
        default:
          return "-";
      }
    },

    pointIsTransfer(point) {
      return point.metadado_4 == "doacao";
    },

    userHasCategory() {
      return ![null, undefined].includes(this.user.categoria.titulo) && this.user.categoria.titulo.trim() != "";
    },
  },
};
