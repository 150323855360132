export default {
    props: {
        nameButton: String
    },

    methods: {
        async submit(callback) {
            if (!this.$refs.form.validate()) {
                this.$toasted.global.error({ msg: "Revise os dados do formulário!" })
                return
            }
            
            const resp = await callback();
            if (resp && (resp.status == 200 || resp.status == 201)) {
                this.$refs.form.reset()
            }
        },

    }
} 