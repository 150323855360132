import Regulation from "../../components/regulation/Regulation.vue"

export default {
    components: { Regulation },

    data: () => ({
        customItemsRegulation: {},
        loading: false
    }),

    async created() {
        this.loading = !this.loading
        this.customItemsRegulation = this.DATACUSTOM.text
        this.loading = !this.loading
    }
}