import { mapGetters, mapState } from "vuex";
import { getSlugFromRoute } from "../../../assets/js/Utils.js"

export default {
    props: {
        customItems: Object
    },

    data: () => ({
        customItemsIndicateFriends: null,
        modelLink: 'www.example.com'
    }),

    created(){
        if(!this.establishment.configuracoes.cliente_pode_indicar){
            this.$router.push({ path: `/${getSlugFromRoute(this.$route.path)}` })
            return
        }
        
        window.scrollTo(0,0)
        this.customItemsIndicateFriends = this.customItems.indicate_friends
    },

    methods: {
        copy() {
            navigator.clipboard.writeText(this.linkPtsvc);
            this.$toasted.global.success({ msg: "Link copiado!" })
        }
    },

    computed: {
        ...mapState(["user", "establishment"]),
        ...mapGetters(["linkPtsvc"])
    },
};