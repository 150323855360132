import { mapState } from "vuex";

export default {
    props: {
        customItems: Object
    },

    data: () => ({
        loading: false,
        gifts: null,
        giftsOfPage: [],
        customItemsMyGifts: null
    }),

    async created() {
        if(!this.$route.path.includes('painel-configuracoes'))
            window.scrollTo(0,0)
            
        this.loading = true
        this.customItemsMyGifts = this.customItems.my_gifts
        if(this.user){
            const resp = await this.MODULOS.gifts.getAllGifts()
            this.gifts = resp.presentes.sort((a, b) => new Date(a.data).getTime() < new Date(b.data).getTime() ? 1 : -1)
        }
        this.loading = false
    },

    computed: {
        ...mapState(["user"])
    }
}