const formatPrizePoints = (prize, establishment, qtd) => {
  return `${establishment.configuracoes.habilitar_pontuacao_inteira
    ? (prize.precoDonuz * (qtd || 1)).toLocaleString('pt-BR')
    : (prize.precoDonuz * (qtd || 1)).toLocaleString('pt-BR', {
      minimumFractionDigits: 2,})
    } 
    ${establishment.moeda} ${prize.precoReais && establishment.configuracoes.habilitar_premio_valor_reais
      ? (' + R$' + (prize.precoReais * (qtd || 1)).toFixed(2).replace('.', ','))
      : ''
    }`;
};

const validateForm = {
  required: [v => !!v || 'Campo obrigatório'],
  firstName: [v => !!v && !v?.includes(' ') || 'Informe somente o primeiro nome'],
  email: [v => !!v && /.+@.+\..+/.test(v) || 'E-mail inválido'],
  phone: [v => !!v && v?.length == 14 || 'Telefone inválido'],
  cellPhone: [v => !!v && v?.length == 16 || 'Celular inválido'],
  phoneOrCellPhone: [v => !!v && v?.length >= 14 && v?.length <= 16 || 'Telefone/Celular inválido'],
  cpf: [v => !!v && v?.length == 14 || 'Cpf inválido'],
  cpfCnpj: [v => !!v && (v?.length == 14 || v?.length == 18) || 'Cpf / Cnpj inválido'],
  date: [v => !!v || 'Data inválida'],
  cep: [v => !!v && v?.length == 9 || 'Cep inválido'],
  password: [v => v?.length >= 8 && validateStrongPassword(v) || 'Mínimo 8 dígitos, e deve conter letras maiúculas, minúsculas, números, e símbolos, e não conter caracteres iguais proximos.'],
  select: [v => !!v || 'Selecione um item'],
  checkBox: [v => !!v || 'Marque essa opção'],
  state: [v => !!v && v?.length == 2 || 'Informe o estado'],
  checkbox: [v => !!v || 'Marcação obrigatória'],
  country: [v => !!v && v?.length == 3 || 'País inválido'],
  image: [
    (v) =>
      v?.length == 0 ||
      v == undefined ||
      v.size < 5000000 ||
      "A foto não pode ser maior que 5 MB"
  ],
  confirmPassword: (v1, v2) => v1 === v2 || 'As senhas estão diferentes'
};

const mask = {
  none: 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX',
  phone: '(##) ####-####',
  cellPhone: '(##) # ####-####',
  cpf: '###.###.###-###',
  cnpj: '##.###.###.####/##',
  cep: '#####-###',
  state: 'AA',
  country: 'XXX'
};

function validateStrongPassword(password) {
  var pattern = /^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$&*~_]).{8,}$/;
  return pattern.test(password);
}

const moneyFormat = v => v?.replace(/\./g, '').replace('R$ ', '')

function getSlugFromRoute(path){
  return path.split('/')[1] ?? ''
}

const formatCep = (cep) => {
    const pattern = /(\d{5})-?(\d{3})/;
    return cep.replace(pattern, "$1-$2");
};

const formatTelefone = (telefone) => {
    const pattern = /(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)(?:((?:9\s?\d|[2-9])\d{3})-?(\d{4}))/;
    return telefone.replace(pattern, "$1($2) $3-$4");
};

export {
  formatPrizePoints,
  moneyFormat,
  validateForm,
  mask,
  getSlugFromRoute,
  formatCep,
  formatTelefone
};