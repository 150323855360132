import { mapState, mapActions } from 'vuex'

export default {
    data: function () {
        return {
            itemsMenuLogged: [
                {
                    name: "Indique um amigo",
                    to: "/indique"
                },
                {
                    name: "Meus dados",
                    to: "/meus-dados"
                },
                {
                    name: "Alterar senha",
                    to: "/alterar-senha"
                },
                {
                    name: "Meu extrato de pontos",
                    to: "/meus-pontos"
                },
                {
                    name: "Meus resgates",
                    to: "/meus-resgates"
                },
                {
                    name: "Ranking",
                    to: "/ranking"
                },
                {
                    name: "Código Pontuavel",
                    to: "/codigo-pontuavel"
                },
                {
                    name: "Meus mimos",
                    to: "/meus-presentes"
                },
                {
                    name: "Transferir Pontos",
                    to: "/transferir-pontos"
                },
                {
                    name: "Sair",
                }
            ],
        }
    },
    methods: {
        ...mapActions(["actionLogout"]),
    

        logoutUser(options) {
            if (options == 'Sair') {
                this.actionLogout()
                location.href = `/${this.$route.params.slug}`
                this.$toasted.global.success({ msg: "Logout realizado!" })
            }
        },

        closeModal(){
            this.$root.$emit('closeModal', false)
        }
    },

    computed: {
        ...mapState(['user', 'establishment'])
    },
}