import HowItWorks from "../../components/how_it_works/HowItWorks.vue"

export default {
    components: { HowItWorks },

    data: () => ({
        customItemsHowItWorks: {},
        loading: false
    }),

    async created() {
        this.loading = !this.loading
        
        this.customItemsHowItWorks = this.DATACUSTOM.text

        this.loading = !this.loading
    }
}