import Vue from 'vue'

import Loading from '../template/generics_components/loading/Loading.vue';
import Form from '../template/generics_components/form/Form.vue';
import Modal from '../template/generics_components/modal/Modal.vue';
import Amount from '../template/generics_components/amount/Amount.vue';
import Confirmation from '../template/generics_components/confirmation/Confirmation.vue';
import Pagination from '../template/generics_components/pagination/Pagination.vue';
import Tooltip from '../template/generics_components/tooltip/Tooltip.vue';

Vue.component('Loading', Loading);
Vue.component('Form', Form);
Vue.component('Modal', Modal);
Vue.component('Amount', Amount);
Vue.component('Confirmation', Confirmation);
Vue.component('Pagination', Pagination);
Vue.component('Tooltip', Tooltip);