import { mapState } from 'vuex'

export default {
    props: {
        customItems: Object
    },

    data: () => ({
        loading: false,
        points: null,
        pointsOfPage: [],
        customItemsMyPoints: null
    }),

    async created() {
        window.scrollTo(0,0)
        this.loading = true
        this.customItemsMyPoints = this.customItems.my_points
        if(this.user){
          const resp = await this.MODULOS.points.getPoints()
          this.points = resp.pontos.sort((a, b) => new Date(a.date).getTime() > new Date(b.date).getTime() ? 1 : -1)
        }
        this.loading = false
      },

    computed: {
      ...mapState(["user"])
    },

    methods: {
      getPointIcon(point) {
        switch (point.operacao) {
          case "D":
            return this.pointIsTransfer(point)
              ? window.ICONS.mdiCurrencyUsd
              : window.ICONS.mdiGiftOutline;
          case "E":
            return window.ICONS.mdiCurrencyUsdOff;
          case "C":
          default:
            return window.ICONS.mdiCurrencyUsd;
        }
      },

      getPointDateDescription(point) {
        if (this.pointIsTransfer(point)) return "Transferido em";
        switch (point.operacao) {
          case "C":
            return "Creditado em";
          case "D":
              return "Resgatado";
          case "E":
            return "Estornado em";
          default:
            return "-";
        }
      },

      pointIsTransfer(point) {
        return point.metadado_4 == "doacao";
      }
    },
}