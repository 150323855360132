import { mapState } from 'vuex'
import { validateForm } from "../../../assets/js/Utils.js";

export default {
    props: {
        customItems: Object
    },

    data: () => ({
        loading: false,
        validateForm,
        showPassword: false,
        form: {},
        customItemsChangePassword: null
    }),

    async created() {
        window.scrollTo(0, 0)
        this.loading = true
        this.customItemsChangePassword = this.customItems.change_password
        this.loading = false
    },
    methods: {
        async changePassword() {
            const resp = await this.MODULOS.client.update({
                senha: this.form.senha,
                id_cliente: this.user.id_cliente,
            });
            if (resp.status == 200)
                this.$toasted.global.success({ msg: "Senha alterada com sucesso!" });

            return resp;
        },
    },

    computed: {
        ...mapState(["user"])
    }
}