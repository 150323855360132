import BannerApp from '../../banner_app/BannerApp.vue'
import HowItWorks from '../../how_it_works/HowItWorks.vue'
import Prizes from '../../prizes/Prizes.vue'

export default {
    components: { 
        HowItWorks, 
        Prizes,
        BannerApp
    },

    data: () => ({
        componentSelected: null,
        customItemsHome: {}
    }),

    created(){
        this.customItemsHome = this.DATACUSTOM.text.home
    },

    methods: {
        selectComponent(keySelected) {
            if(keySelected == 'how_it_works')
                this.componentSelected = HowItWorks
            if(keySelected == 'prizes')
                this.componentSelected = Prizes
            if(keySelected == 'banner_app')
                this.componentSelected = BannerApp
        
            this.$root.$emit('selectComponent', {
                componentSelected: this.componentSelected,
                customItems: this.customItemsHome,
                key: keySelected,
                home: true
            })
        }
    }
}