import { mapState, mapActions } from 'vuex';
import { formatCep } from '../../../../assets/js/Utils.js'

export default {
    props: {
        deliveryAddressesModalId: String
    },

    data: () => ({
        deliveryAdresses: [],
        selectedAddress: null,
    }),
    
    async created() {
        this.deliveryAdresses = this.user.deliveryAddresses;
        this.selectedAddress = this.user.selectedDeliveryAddress;
    },

    mounted() {
        if(this.deliveryAdresses.length == 0)
            document.getElementById("new-address").click();
    },

    methods: {
        getAddressSummary(address) {
            const {rua, numero, complemento, bairro, cidade, estado, pais, cep} = address;
            return `${rua}, ${numero} - ${complemento} - ${bairro}, ${cidade} - ${estado}, ${pais}, ${formatCep(cep)}`;
        },

        markAddress(address) {
            this.selectedAddress = address;    
        },

        selectAddress() {
            this.actionSetSelectedDeliveryAddress(this.selectedAddress);
            this.$emit("delivery-address-updated");
            document.querySelector(`#${this.deliveryAddressesModalId}`)
                .parentElement //div
                .parentElement //div.content
                .parentElement //div.v-card__text
                .parentElement //div.v-card
                .querySelector(".header-modal button.close")
                .click();
        },

        updateAddress(address) {
            this.$emit("update-address", address);
            this.$emit("show-component", "form");
        },

        newAddress() {
            this.updateAddress(null);
        },

        ...mapActions(["actionSetSelectedDeliveryAddress"])
    },

    computed: {
        ...mapState(["user"]),
    },
}